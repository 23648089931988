/* raleway-300 - latin */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 300;
	src: url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/raleway/raleway-v34-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* raleway-regular - latin */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 400;
	src: url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/raleway/raleway-v34-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* raleway-600 - latin */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 600;
	src: url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/raleway/raleway-v34-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* raleway-700 - latin */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 700;
	src: url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/raleway/raleway-v34-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* raleway-800 - latin */
  @font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 800;
	src: url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/raleway/raleway-v34-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }



// @import 	url('https://fonts.googleapis.com/css?family=Raleway:300,400,700,800');
@import 	url('https://fonts.googleapis.com/css?family=Playfair+Display:400i,400');
// Spot variables
@import "spot/variables";

// Core variables & mixins
@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

// Reset and dependencies
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/print";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";

// Core CSS
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/type";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/code";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/grid";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/buttons";

// Components
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/navs";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/navbar";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/pager";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/labels";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/badges";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/jumbotron";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/thumbnails";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/alerts";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/media";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/list-group";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/panels";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/wells";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/close";

// Components w/ JavaScript
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/modals";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/tooltip";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/popovers";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/carousel";

// Utility classes
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
//@import "../../BowerComponents/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";

// Font Awesome
//@import "../../BowerComponents/fontawesome/scss/font-awesome.scss";

// Spot files
@import "spot/_int-legal.scss";
@import "spot/reset";
@import "spot/effects";
@import "spot/mixins";
@import "spot/overrides";
@import "spot/fonts";
@import "spot/custom-font-icons";
@import "spot/layout";
@import "spot/navigation";
@import "spot/content";
@import "spot/aggregator";

// Extensions files
//@import "spot/ext-powermail";
//@import "spot/ext-spotcore";
//@import "spot/ext-spotreport";
//@import "spot/ext-spotsocial";
//@import "spot/ext-spotdata";
//@import "spot/ext-spotmedia";
//@import "spot/ext-spothotel";
//@import "spot/ext-spotprotel";
//@import "spot/ext-spotshop";

// Integrations files
//@import "spot/int-reviews";
@media (max-width:992px) {
	@import "mediaq/max992";
}
@media (max-width:768px) {
	@import "mediaq/max768";
}
@media (max-width:500px) {
	@import "mediaq/max500";
}

/*
-------------------------------------------------------------------
- OVERRIDE :: Buttons
-------------------------------------------------------------------
*/
.btn-primary {
	@include button-variant-default($white-color, $theme-base-color, $theme-base-color);
}

/*
-------------------------------------------------------------------
- OVERRIDE :: Containers, Grids and Columns
-------------------------------------------------------------------
*/
/*
.container {
    
    @media (min-width: $screen-xl-min) {
        width: $container-xl;
    }
    
    @media (min-width: $screen-xxl-min) {
        width: $container-xxl;
    }
    
    @media (min-width: $screen-ul-min) {
        width: $container-ul;
    }
}

@media (min-width: $screen-xl-min) {
	.make-grid(xl);
}
	
@media (min-width: $screen-xxl-min) {
	.make-grid(xxl);
}

@media (min-width: $screen-ul-min) {
	.make-grid(ul);
}

// Generate extra large columns
@mixin make-xl-column($columns, $gutter: $grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-left:  ($gutter / 2);
	padding-right: ($gutter / 2);
	
	@media (min-width: $screen-xl-min) {
		float: left;
		width: percentage(($columns / $grid-columns));
	}
}

@mixin make-xl-column-offset($columns) {
	@media (min-width: $screen-xl-min) {
		margin-left: percentage(($columns / $grid-columns));
	}
}
@mixin make-xl-column-push($columns) {
	@media (min-width: $screen-xl-min) {
		left: percentage(($columns / $grid-columns));
	}
}
@mixin make-xl-column-pull($columns) {
	@media (min-width: $screen-xl-min) {
		right: percentage(($columns / $grid-columns));
	}
}

// Generate extra extra large columns
@mixin make-xxl-column($columns, $gutter: $grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-left:  ($gutter / 2);
	padding-right: ($gutter / 2);
	
	@media (min-width: $screen-xxl-min) {
		float: left;
		width: percentage(($columns / $grid-columns));
	}
}
@mixin make-xxl-column-offset($columns) {
	@media (min-width: $screen-xxl-min) {
		margin-left: percentage(($columns / $grid-columns));
	}
}
@mixin make-xxl-column-push($columns) {
	@media (min-width: $screen-xxl-min) {
		left: percentage(($columns / $grid-columns));
	}
}
@mixin make-xxl-column-pull($columns) {
	@media (min-width: $screen-xxl-min) {
		right: percentage(($columns / $grid-columns));
	}
}

// Generate ultra large columns
@mixin make-ul-column($columns, $gutter: $grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-left:  ($gutter / 2);
	padding-right: ($gutter / 2);
	
	@media (min-width: $screen-ul-min) {
		float: left;
		width: percentage(($columns / $grid-columns));
	}
}
@mixin make-ul-column-offset($columns) {
	@media (min-width: $screen-ul-min) {
		margin-left: percentage(($columns / $grid-columns));
	}
}
@mixin make-ul-column-push($columns) {
	@media (min-width: $screen-ul-min) {
		left: percentage(($columns / $grid-columns));
	}
}
@mixin make-ul-column-pull($columns) {
	@media (min-width: $screen-ul-min) {
		right: percentage(($columns / $grid-columns));
	}
}

// Large grid
// -> Columns, offsets, pushes, and pulls for the large desktop device range.
@media (min-width: $screen-xl-min) {
	@include make-grid(xl);
}

// Large grid
// -> Columns, offsets, pushes, and pulls for the large desktop device range.
media (min-width: $screen-xxl-min) {
  @include make-grid(xxl);
}

// Large grid
// -> Columns, offsets, pushes, and pulls for the large desktop device range.
@media (min-width: $screen-ul-min) {
  @include make-grid(ul);
}
*/
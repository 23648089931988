.paddingit{
	padding:0;
}

header #header_right #header_right_up,
header #header_right #header_right_down{
	width: auto;
}

#newordercontent,
#servicenavi_top{
	display: none;
}
#bottomservicenavi{
	width: 100%;
	a{
		width: 50%;
		display: inline-block;
		text-align: center;
		padding:3vw 0;
	}
	#topservice_69{
		display: none;
	}
}
#newordermobilecontent{
	display: block;
}
.item-facebook{
	width: 100%;
	& + .item-instagram{
		img{
			float: right;
		}
	}
}
.item-instagram{
	width: 50%;
}
h2{
	line-height: 1.2em;
}
body{
	#mobilemenu{
		.main-menu{
			li.sub{
				//width: 100%;
				display: block;
				float: none;
				font-size: 1.4rem;
				padding-top: 1em;
				padding-bottom: 1em;
				&:last-child{
				    padding: 0 1em;
				}
				a{
					padding: .5em 1rem;
					width: auto;
					display: inline;
				}
				.paddingit.backgroundit{
					border-top:0;
					position: static;
					padding:0;
					margin-top: 0;
					ul{
						padding: 2rem 0 3rem 0;
						li.subli{
							width: 100%;
							height: 5rem;
							a{
								float: left;
								width: 100%;
							}
							.liner{
								display: none;
							}
						}
					}
				}
				&.open{
					.paddingit.backgroundit{
						max-width: 0;
					}
					&.hover{
						.paddingit.backgroundit{
							max-width: none;
						}
					}
				}
			}
		}
	}	
}
.halber{
	width:100%;
	margin-left: 0 !important;
}
.zweihalber{
	.halber + .halber{
		margin-top: 3em;
	}
}

/*
------------------------------------------------------------------------------
Headerbild
------------------------------------------------------------------------------
*/

.headerbild{
	.headertext{
		z-index: 90;
		position: absolute;
		right:$lepadding;
		//bottom:10vw;
		bottom:25%;
		text-align: right;
		width: auto;
		h3{
			color: $white-color;
			font-family: $mainfont;
			font-size: 1.2rem;
			font-weight: 700;
			letter-spacing: 0.0555555556em;
			text-transform: uppercase;
			//padding-right: 1.5em;
		}
		h2{	
			padding-top: 0;
			color: $white-color;
			font-size: 4rem;
			line-height: 1em;
			/* Text style for "Platz zum" */
			font-style: normal;
			font-family: $mainfont;
			font-weight: 300;
			br{
				height: 1px;
			}
			i{
				font-size: 4rem;
				font-family: $titlefont;
				line-height: 1em;
			}
			b{
				line-height: 1em;
				font-weight: 700;
				font-size: 4rem;
			}
		}
	}
	&:after{
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background:rgba(0,0,0,0.3);
	}
}

/*
------------------------------------------------------------------------------
Video
------------------------------------------------------------------------------
*/
.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.bottommargin-1{
    margin-bottom:5pc;
}
/*
------------------------------------------------------------------------------
Content
------------------------------------------------------------------------------
*/
h1{

	color: $theme-alt-color;
	font-family: $titlefont;
	font-size: 4rem;
	font-weight: 400;
	line-height: 1em;
	padding-bottom: 3rem;
	font-style: italic;
	b{
		font-family: $mainfont;
		font-weight: 800;
		font-style: normal;
		font-size: 4rem;
		line-height: 1em;
	}
	span{
		font-family: $mainfont;
		font-size: 4rem;
		font-weight: 400;
		line-height: 1em;
		font-style: normal;
	}
	& + h2{
		padding-top: 0;
	}
}
h2{
	color: $darktitelcolor;
	font-family: $titlefont;;
	font-size: 1.6rem;
	font-weight: 400;
	line-height: 1.1em;
	padding: 1.57em 0 1em 0;
	font-style: italic;
	&+ol,
	&+ul{
		padding-top: 0 !important;
	}
	&+h3{
		padding-top: 0!important;
	}

}
h3{
	color: $darktitelcolor;
	font-size: 1.0666666667em;
	font-weight: 700;
	letter-spacing: 0.0625em;
	text-transform: uppercase;
	padding: 1.2em 0 0.6em 0;
	line-height: 1.5em;
	&+ol,
	&+ul{
		padding-top: 0 !important;
	}
}
ol,
ul{
	& + h2,
	& + h3{
		padding-top:0.5rem !important;
	}
}
.content_wrap{
	color:$mainfontcolor;
	a{
		color:$theme-base-color;
		font-weight: 600;
		&:hover{
			border-bottom: 3px solid rgba($theme-base-color,0.4);
		}
	}
	ol{
		padding: 1rem 0 2rem 2rem;
		list-style-type: none;
    	counter-reset: ol-counter;
		li{
			position: relative;
			padding-left: 0.5em;
			&:before{
					position: absolute;
					left:-1em;
					content: counter(ol-counter)'.\00a0\00a0';
		    		counter-increment: ol-counter;
		    		font-size: 1.1em;
		    		font-weight: 600;
		    		line-height: 1.2em;
				}
			}
	}
	ul{
		padding: 1rem 0 2rem 1.5rem;
		li{
			list-style: none;
			position: relative;
			&:before{
				//content:'\b7\a0';
				content:'\2022';
				//margin-right: 1.3rem;
				//margin-left: -1rem;
				//font-size: 2rem;
				line-height: 1.6rem;
				position: absolute;
				left: -18px;
			}
		}
	}
}

.homelink{
	.rechts{
		.homelink_bild:first-child{
			display: none;
		}
	}
	a{
		display: block;
		float: left;
		//color:$white-color;
		color:rgba($black-color,0.7);
		margin-bottom:0.333333rem;
		position:relative;
		border:1px solid $bgcolor;
		transition:0.2s ease-in-out all 0.05s;
		&:hover{
			color:$black-color;
			//border-bottom: none !important;
			border:1px solid $mainfontcolor;
			background:$bgcolor;
			img{
				opacity:1;
				transition:0.2s ease-in-out all 0.2s;
			}
			
			/*
			.homelink_pfeil{
				transform:scale(3.5);
				position: relative;
				svg{
					transform:scale(0.2941176471);
					position: absolute;
					bottom:3px;
					left:3px;
					transition-property: none;
				}
			}
			*/

		}
		img{
			opacity: 0.8;
		}
		span{
			display: block;
			float: left;
			height: 100%;
		}
		.home_text{
			width: 34%;
			/*padding:3.5rem 1em 0;*/
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			padding: 0.8em;
			.homelink_titel{
				font-size: 1.6666666667rem;
				font-weight: 700;
				line-height: 1.8rem;		
				margin-bottom: 1.2rem; 		
				position: relative;
				z-index: 900;
			}
			.homelink_text{
				font-weight: 400;
				position: relative;
				z-index: 900;
			}
			.homelink_pfeil{
				display:none;
			}
			/*
			.homelink_pfeil{
				transform-origin: left bottom;
				transition:all 0.5s ease 0s;
				position: absolute;
				bottom:0;
				width:5rem;
				height: 4.98rem;
				line-height: 5rem;
				//background:rgba($black-color,0.15);
				background:#ffc125;
				text-align: center;
				margin-left: -1rem;
				svg{
					width: 14px;
					height: auto;
					vertical-align: middle;
					transition-property: none;
					//position: absolute;
					//bottom:3px;
					//left:3px;
					g{
						fill:$white-color !important;
					}
				}
			}
			*/
		}
		.homelink_bild{
			width:66%;
		}
	}
	/*
	.blau{
		background:#416ca7;
	}
	.rot{
		background:#b43d3e;
	}
	.gelb{
		background:#ffc125;
	}
	.gruen{
		background:#67ac50;
	}
	*/
}
.blau{
	color:#416ca7;
}
.rot{
	color:#b43d3e;
}
.gelb{
	color:#ffc125;
}
.gruen{
	color:#67ac50;
}
.googlemaplogo{
	float:left;
	width: 160px;
	height: auto;
}
#map_canvas{
	strong{
		margin-top: 2rem;
	}
}
#uid44{
	#map_canvas{
		background:url(/typo3conf/ext/spotwebsite/Resources/Public/Assets/Images/gmap_samedan.png) no-repeat;
		background-size:cover;
		background-position:center center;
	}
}
#uid45{
	#map_canvas{
		background:url(/typo3conf/ext/spotwebsite/Resources/Public/Assets/Images/gmap_poschiavo.png) no-repeat;
		background-size:cover;
		background-position:center center;
	}
}
#uid46{
	#map_canvas{
		background:url(/typo3conf/ext/spotwebsite/Resources/Public/Assets/Images/gmap_scuol.png) no-repeat;
		background-size:cover;
		background-position:center center;
	}
}
#uid47{
	#map_canvas{
		background:url(/typo3conf/ext/spotwebsite/Resources/Public/Assets/Images/gmap_jaura.png) no-repeat;
		background-size:cover;
		background-position:center center;
	}
}
#uid1{
	.quickinfo_wrap{
		h1.c-heading_title{
			display: none;
		}
	}
}
.wrap_kalenderdetail{
	padding: 2rem 0;
	&.selected{
		background: $bgcolor;
	}
	.datum_wrap{
		width: 25%;
		padding-right: 2rem;
		color:$black-color;
		.block{
			line-height: 0.3em;
			font-weight: 700;
		}
		.datum_kalender_von,
		.datum_kalender_bis{
			display: block;
			width: 100%;
			font-weight: 700;
		}
		.datum_kalender_hour{
			margin-top: 1rem;
		}
	}
	.event_text{
		width: 75%;
		h2{
			line-height: 1em;
			padding: 0 0 0.4em 0;
		}

	}
	& + .wrap_kalenderdetail{
		border-top: 1px solid rgba($mainfontcolor,0.5);
	}
}


.powermail_form{
	legend{
		margin-bottom: 15px;
	}
	&.form-horizontal .control-label{
		text-align: left;
	}
	.products{
		margin-bottom: 15px;
	}
	.powermail_fieldwrap_produkte{
		padding: 5px 0;
		transition: background 0.3s;
		margin-bottom: 0;
		&:hover{
			background: $bgcolor;
		}
	}
}
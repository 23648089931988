.spotsocial{
	display: none;
}
.item-facebook{
	width: 40%;
	text-align: center;
	position: relative;
	.item-wrap{
		z-index: 30;
		position: relative;
	}
	/*
	&:before{
		content: "";
	    position: absolute;
	    z-index: 0;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    background: $theme-base-color;
	    transform: scale(0);
	    transition-property: transform;
	    transition-duration:0.5s;
	    transition-timing-function: ease-out;
	}
	*/
	box-shadow: 0;
	transition: box-shadow 1s;
	&:hover{
		box-shadow: 0 0 3px #515151;
		/*
		&:before{
			transform: scale(1);
		}
		*/

	}
	
	.date,
	.profile-name{
		text-transform: uppercase;
		font-size:0.6666666rem;
		letter-spacing: 0.2rem;
		color:$black-color;
		font-weight: 700;
	}
	.date{
		padding-top: 1rem;
		font-size: 0.666rem;
	}
	.profile-name{
		padding-bottom: 1rem;
	}

	.message,
	.description{
		color:$theme-alt-color;
		font-size: 0.8rem;
		line-height: 1.4rem;
		padding-top: 1em;
	}
	.item-wrap{
		padding: 30px 34px;
	}
	.item-body{
		svg{
			width: 10px;
		}
	}
}
.item-instagram{
	width:20%;
	.picture{
		overflow: hidden;
		img{
			transition: all 0.5s ease
		}
		&:hover{
			img{
			    transform: scale(1.2);
			}
		}
		position: relative;
		svg{
			position: absolute;
			bottom: 1rem;
			right: 1rem;
			width: 26px;
			g{
				fill:$white-color;
			}
		}
	}
}
.item:nth-child(4){
	clear:both;
}
#newordercontent{
	margin-top: 2.3333333333rem;
	background: $white-color;
}
#kekschecker{
    position: fixed;
    border:1px solid rgba(0, 0, 0, 0.2);
    bottom:0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1400px;
    z-index: 9999999;
    padding:2em;
    width: 100%;
    box-sizing: border-box;
    background:#ffffff;
    display: none;
    > p{
      margin:0 0 20px;
    }
  }
#kekschecker .button{
    height: 3em;
    line-height: 3em;
    text-align: center;
    display: inline-block;
    background:$theme-base-color;
    color:$black-color;
    border:1px solid $black-color;
    padding: 0 2em;
  }
#kekschecker .button:hover{
  background:$black-color;
  color:$theme-base-color;
  border-color:$theme-base-color;
}
.cookieright{
    float: right;
  }
#infotext{
    display: none;
  }
#infotext.show{
    display: block;
    position: fixed;
    overflow: hidden;
    overflow-y: scroll;
    z-index: 999999999999;
    top:0;
    bottom: 0;
    left:0;
    right: 0;
    background: rgba(0,0,0,0.95);
  } 
#infotext.show > div{
    background:#ffffff;
    padding: 4em 2em;
    max-width: 800px;
    margin:auto;
    float: none;
    overflow: visible;
    position: relative;
    &>*{
      float: none;
    }
  }
#closer{
    position: absolute;
    top:20px;
    right: 20px;
    background:inherit;
    border-radius: 1em;
    border: 1px solid ;
    width:2em;
    height: 2em;
    line-height: 2em;
    text-align: center;
    cursor: pointer;
    cursor: hand;
  }
#showlegal{
    text-decoration:underline
}
#infotext, #disclaimershow {
  h2,h3,h4,h5{
    padding:0;
    color: $darktitelcolor;
  }
  h2 {
    text-transform: uppercase;
    letter-spacing: 2px;
    margin:25px 0 15px 0;
  }
  h3{
    margin:20px 0 10px 0;
  }
  h4{
    margin:10px 0 5px 0;
  }
  h5{
    margin:5px 0 3px 0;
  }
  ul {
    margin:10px 0 20px 15px;
    padding:0;
    li {
      list-style-type:disc;
      margin-left:30px;
      clear:both;
      p{
        margin:0;
      }
    }
  }
}
#disclaimershow,
#infotext{
	a{
		color:$theme-base-color;
		font-weight: 600;
		&:hover{
      color:$black-color;
			border-bottom: 3px solid rgba($theme-base-color,0.4);
		}
	}
}
#kekschecker{
	a{
		color:$theme-base-color;
		font-weight: 600;
		&:hover{
			border-bottom: 3px solid rgba($theme-base-color,0.4);
		}
	}
}
  @media (max-width: 500px){
      span.button{
          margin-bottom: 1em;
          clear: both;
          margin:0.5em 0;
          width:100%;
          float:none;
      }
      #kekschecker {
          p, a{
            line-height:1.3em;
          }
      }
  }
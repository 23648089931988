/*
-------------------------------------------------------------------
- VARIABLES :: Spaces
-------------------------------------------------------------------
*/
$accessibility-icon-margin: 0 4px 0 0;

/*
-------------------------------------------------------------------
- VARIABLES :: Theme colors
-------------------------------------------------------------------
*/
$white-color: #ffffff;
$black-color: #000000;


$theme-base-color: #ffc125;
$theme-alt-color: rgba(0, 0, 0, 0.7); // EDIT!
$theme-mono-color: #878787;
$theme-error-color: #ff0000;
$bgcolor: #f4f4f4;
$titelcolor: #c4c4c4;
$darktitelcolor: #4c4c4c;
$mainfontcolor: #898989;
$brightfont: #929292;
$anygray: #979797;
$blue: #416ca7;
$lepadding: 8.2%;

/*
-------------------------------------------------------------------
- VARIABLES :: Bootstrap colors
-------------------------------------------------------------------
*/
$body-bg: $white-color;
$text-color: $black-color;

/*
-------------------------------------------------------------------
- VARIABLES :: Links
-------------------------------------------------------------------
*/
//$link-color:				$theme-base-color;
//$link-hover-color:      	$theme-alt-color;

/*
-------------------------------------------------------------------
- VARIABLES :: Typography
-------------------------------------------------------------------
*/
//$font-family-sans-serif:					Arial, sans-serif;
//$font-family-serif:						"Times New Roman", serif;
//$font-size-base:							16px;
//$line-height-base:						1.75;

/*
-------------------------------------------------------------------
- VARIABLES :: Iconography
-------------------------------------------------------------------
*/
//$icon-font-path:          	"/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts";
//$fa-font-path:        		"/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts";

$mainfont: "Raleway";
$titlefont: "Playfair Display";
/*
-------------------------------------------------------------------
- VARIABLES :: Components
-------------------------------------------------------------------
*/
//$list-indent:               24px;

/*
-------------------------------------------------------------------
- VARIABLES :: Forms
-------------------------------------------------------------------
*/
//$input-bg:                      	#F5F5F5;
//$input-border:				    #F5F5F5;
//$input-color:                   	#979797;
//$input-border-radius:       		0;
//$input-color-placeholder:       	#D2D2D2;
//$input-height-base:				50px;
//$input-border-focus:            	$theme-base-color;

/*
-------------------------------------------------------------------
- VARIABLES :: Components
-------------------------------------------------------------------
*/
//$border-radius-base:        	0;

/*
-------------------------------------------------------------------
- VARIABLES :: Grid system
-------------------------------------------------------------------
*/
$grid-gutter-width: 30px !default;

/*
-------------------------------------------------------------------
- VARIABLES :: Media queries breakpoints
-------------------------------------------------------------------
*/
$screen-md: 1025px;
$screen-xl: 1600px;
$screen-xxl: 2000px;
$screen-ul: 2500px;

/*
-------------------------------------------------------------------
- VARIABLES :: Extra media queries breakpoints
-------------------------------------------------------------------
*/
$screen-xl-min: $screen-xl;
$screen-xxl-min: $screen-xxl;
$screen-ul-min: $screen-ul;
$screen-lg-max: ($screen-xl-min - 1);
$screen-xl-max: ($screen-xxl-min - 1);
$screen-xxl-max: ($screen-ul-min - 1);
$container-extra-large-desktop: (($screen-xl - 60px + $grid-gutter-width));
$container-extra-extra-large-desktop: (($screen-xxl - 60px + $grid-gutter-width));
$container-ultra-large-desktop: (($screen-ul - 60px + $grid-gutter-width));
$container-xl: $container-extra-large-desktop;
$container-xxl: $container-extra-extra-large-desktop;
$container-ul: $container-ultra-large-desktop;
header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 300000;

    .paddingit {
        padding-bottom: 2rem;
    }
}

#maincontentwrap {
    margin-top: 141px;
}

#newordermobilecontent {
    display: none;
}

#logo {
    width: 25%;
    margin-top: 3.2rem;

    a {
        svg {
            width: 290px;
        }
    }
}

#header_right {
    width: 75%;

    #langmenu {
        float: none;
        width: auto;
        display: inline-block;
        height: 2.333rem;
        line-height: 2.333rem;
        margin-left: 1em;
        margin-right: 4em;
        margin-top: 2rem;
        font-weight: 700;
        min-width: 75px;

        a {
            color: $theme-alt-color;

            &.active {
                opacity: 0.45;
            }
        }
    }

    nav {
        width: auto;
        float: right;
    }
}

#bottomservicenavi,
#servicenavi_top {
    width: auto;
    display: inline-block;
    float: none;
    background: $theme-alt-color;

    a {
        display: inline-block;
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: 700;
        color: $white-color;
        padding: 10px 30px;
        float: left;
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px transparent;
        position: relative;
        -webkit-transition-property: color;
        transition-property: color;
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba($theme-base-color, 0.8);
            -webkit-transform: scaleY(0);
            transform: scaleY(0);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            transition: transform 0.5s ease-out;
        }

        &#topservice_9 {
            background: $theme-base-color;

            &:before {
                background: rgba($theme-alt-color, 0.8);
            }
        }

        &#topservice_70 {
            background: $blue;

            &:before {
                background: rgba($theme-alt-color, 0.8);
            }
        }

        &#topservice_10,
        &#topservice_69 {
            background: $theme-alt-color;
        }

        &:hover {
            &:before {
                transform: scaleY(1);
                transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
            }
        }
    }
}

.main-menu {
    padding-top: 2.3333rem;
    width: auto;
    float: right;

    &>li {
        display: inline-block;
        float: left;
        padding: 0 0.75em;

        &:last-child {
            padding-right: 0;
        }

        &>a {
            display: block;
            font-size: 1.2rem;
            color: $black-color;
            font-weight: 700;
            transition: all 0.3s ease;
            position: relative;
            text-transform: uppercase;

            &:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: 0;
                left: 0;
                background-color: $theme-base-color;
                visibility: hidden;
                -webkit-transform: scaleX(0);
                -ms-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transition: all 0.3s ease-in-out 0s;
                transition: all 0.3s ease-in-out 0s;
            }

            &.current,
            &.open,
            &:hover {
                color: $theme-base-color;

                &:after {
                    visibility: visible;
                    -webkit-transform: scaleX(1);
                    -ms-transform: scaleX(1);
                    transform: scaleX(1);
                }
            }

        }

        &.sub {

            //position: relative;
            .paddingit.backgroundit {
                //display: none;
                z-index: 1;
                transition: all 1s ease-out 0s;
                max-height: 0;
                overflow: hidden;
                position: absolute;
                left: 0;
                top: calc(100%-3rem);
                background: $white-color;
                width: 100%;

                ul {
                    padding: 4rem 0 0 0;

                    li {
                        float: left;
                        width: 33%;
                        text-align: left;
                        height: 7rem;

                        //padding-bottom:  2rem;
                        a {
                            font-family: $titlefont;
                            font-weight: 400;
                            color: $theme-alt-color;
                            display: inline-block;
                            float: left;
                            transition: all 0.5s ease-out 0s;
                            font-style: normal;

                            b {
                                font-family: $mainfont;
                                font-weight: 700;
                                display: block;
                                text-transform: uppercase;
                            }

                            .liner {
                                display: block;
                                width: 30%;
                                border-bottom: 1px solid $anygray;
                                transition: all 0.3s ease-in-out 0s;
                                padding-top: 1rem;
                            }

                            &:hover {
                                color: $black-color;

                                .liner {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }

            &.hover,
            &:hover {
                .paddingit.backgroundit {
                    //display: block;
                    max-height: none;
                    z-index: 90000;
                }
            }
        }
    }
}

body {
    #mobilemenu {
        background: $white-color;
        height: 0;
        max-height: 0;
        position: fixed;
        overflow: hidden;
        transition: all 0.5s ease-out 0s;
        z-index: 500000;
        top: 0;

        &>.main-menu {
            top: 0;
            width: 100%;
            transition: all 0.5s ease-out 0s;
            text-align: center;

            &>li {
                float: none;
                display: inline-block;
            }
        }

        .main-menu {
            &>li.sub {
                .paddingit.backgroundit {
                    padding: 0 20%;

                    li {
                        width: 50%;
                        text-align: center;

                        a {
                            float: none;

                            .liner {
                                margin: auto;
                            }
                        }
                    }
                }
            }
        }

        .hamburger {
            position: absolute;
            top: 60px;
            right: 30px;
            display: block;
        }
    }

    &.open {
        overflow: hidden;

        #mobilemenu {
            height: 100%;
            max-height: 2500px;
            overflow-y: scroll;

            &>.main-menu {}
        }
    }
}

#uid1 {
    .content_wrap {
        padding: 0.333333rem 0 0 0;
    }
}

#largecontenthere {
    display: none;
}

.content-main {
    .backgroundit {
        padding: 0 1rem 3rem 1rem;
    }
}

.content_wrap {
    width: 68%;
    padding-right: 2rem;
    padding-top: 3rem;
}

.quickinfo_wrap {
    width: 32%;

    & *:first-child {
        .righttitel {
            background: $theme-base-color;
            margin-top: -(2*2.1rem + 1.6rem);
            position: relative;
            z-index: 3000;
        }
    }
}

.righttitel {
    text-align: center;
    text-transform: uppercase;
    background: $theme-alt-color;
    color: $white-color;
    font-weight: 700;
    padding: 2.1rem 0;
}

.rightlink {
    position: relative;

    &.svgright {
        svg {
            float: right;
            height: 13px;
        }
    }

    &.svgleft {
        svg {
            float: left;
            width: 15%;
            padding-right: 1rem;
            padding-top: 0.5rem;
            height: 25px;
        }

        .wrap_files,
        .wrap_kalender {
            float: left;
            width: 85%;

            .datum_kalender_von {
                font-size: 1rem;
                font-weight: 800;
                line-height: 1.1em;
            }

            h3 {
                font-size: 1rem;
                text-transform: none;
                line-height: 1.1em;
                font-weight: 400;
                color: $brightfont;
                padding: 0;
            }
        }
    }

    &:nth-child(even) {
        background: $bgcolor;
    }

    a {
        //padding:2.1rem 2rem;
        padding: 1.8rem 2rem;
        color: $theme-alt-color;
        font-weight: 700;
        display: block;
        height: 100%;
        width: 100%;
        position: relative;
        z-index: 1;
        transition: all 0.5s ease-out 0s;
        float: left;

        svg {
            vertical-align: middle;
            height: 16px;
            width: auto;
            transition: all 0.3s ease-in-out 0s;
        }
    }

    box-shadow: none;
    transition: box-shadow 1s;

    /*
	&:before{
		content: "";
	    position: absolute;
	    z-index: 0;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    background: rgba($theme-base-color,0.8);
	    -webkit-transform: scaleX(0);
	    transform: scaleX(0);
	    -webkit-transform-origin: 0 50%;
	    transform-origin: 0 50%;
	    transition: all 0.5s ease-out 0s;
	}
	*/
    &:hover {
        box-shadow: 0 0 3px #515151;
        z-index: 300;

        a {
            color: $black-color;
        }

        /*
		&:before{
			transform: scaleX(1);
		}
		svg{
			height: 20px;
			width:auto;
			//transform:rotate(-90deg);
			//transform:translate(30px,30px	);
		}
		*/
    }
}

.dopadding {
    //padding:80px 200px 20px 200px;
    padding: 20px 200px 20px 200px;
}

.bottommarginer {
    //margin-bottom: 60px;
    margin-bottom: 20px;
}

.google_addr {
    line-height: 1.3rem;

    input {
        height: 3rem;
        line-height: 3rem;
        padding: 0rem 0.5rem;

        &[type="submit"] {
            background: $theme-base-color;
            color: $white-color;
            border: none;
        }
    }
}

p+figure {
    padding-top: 3rem;
}

figure {
    padding: 1em 0 2em 0;

    figcaption,
    a figcaption {
        color: $brightfont;
        font-weight: normal;
        padding-top: 1.5rem;
        font-size: 0.8666666667rem;
        float: left;
    }

    &+h2,
    &+h3 {
        padding-top: 0;
    }
}

footer {
    .backgroundit {
        * {
            font-size: 0.8666666667rem
        }

        a {
            color: $white-color;

            &:hover {
                color: rgba($white-color, 0.8);
            }
        }

        background: $theme-alt-color;
        color: $white-color;
        padding: 0 1rem 50px 1rem;

        h3 {
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 0.1333333333;
            margin: 2.1333rem 0 0.8rem 0;
            font-size: 0.9333333333rem;
            color: $white-color;
        }

        #bottomnavi {
            margin-top: 2rem;

            li {
                float: left;
                width: auto;

                a {
                    font-weight: 700;
                    margin-right: 25px;
                }
            }
        }

        .socialmedia {
            margin-top: 2em;

            svg {
                height: 18px;
                width: auto;
                margin-right: 1em;

                g {
                    fill: $white-color;
                }
            }

            a:hover {
                svg g {
                    fill: rgba($white-color, 0.8);
                }
            }
        }
    }
}
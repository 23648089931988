.open{
  .hamburger{
      background:none;
      &:before {
      transform: rotate(-45deg);
      top:-1em;
    }
      &:after {
      transform: rotate(45deg);
      bottom: 0.5em;
    }  
  }
}
.hamburger{
  display: none;
  z-index: 900000;
  width: 30px;
  height: 3px;
  background:$mainfontcolor;
  position: relative;
    &:before {
      position: absolute;
      top:-0.5em;
      content:"\00a0";
      border-top: 3px solid $mainfontcolor;
      display: block;
      width: 100%;
      transition:all 0.5s ease 0s;
      //transform-origin: right center;
  }
    &:after {
      position: absolute;
      bottom:-0.5em;
      content:"\00a0";
      border-bottom: 3px solid $mainfontcolor;
      display: block;
      width: 100%;
      transition:all 0.5s ease 0s;
      //transform-origin: right center;
  }    
}


/*
}
.menu-toggle {
  color: white;
  text-decoration: none;
  background:red;
}

.menu-label {
  margin-bottom:-.2em;
  display:inline-block;
  vertical-align: middle;
}

.menu-icon + .menu-label {
  margin-left:.2em;
}


.menu-icon {
  cursor: pointer;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: .5em;
  color: inherit;
  background: currentColor;
  border-radius: .5em;
  height: .4em;
  width: 2.6em;
}

.menu-icon:before,
.menu-icon:after {
  border-radius: .5em;
  height: .4em;
  width: 100%;
  left: 0;
  background: currentColor;
  position: absolute;
  display: block;
  content: '';
}
.menu-icon:before {
  top: -.8em; 
}
.menu-icon:after {
  top: .8em;
}

.menu-icon,
.menu-icon:before,
.menu-icon:after {
  transition: all .5s ease-in-out;
}


.open .menu-icon {
  background-color: transparent;
  transform: rotate(45deg) translate(0%, -50%);
}
.open .menu-icon:before,
.open .menu-icon:after {
  top: 0em;
}
.open .menu-icon:before {
  transform: rotate(180deg);
}
.open .menu-icon:after {
  transform: rotate(270deg);
}
*/

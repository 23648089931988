#logo{
	width: 60%;
}
#header_right{
	width: 30%;
}
header #header_right #header_right_down{
	#langmenu{
		/*
		.langwrap{
			display: none;
		}
		*/
	
		display: flex;
		.langwrap{
			margin-top:-0.5em;
			&:after{
				display: none;
			}
		}
	}
}

.homelink{
	.rechts{
		.homelink_bild:first-child{
			display: block;
		}
		.homelink_bild:last-child{
			display: none;
		}
	}
	a{
		.homelink_bild{
			width: 100%;
		}
		.home_text{
			width: 100%;
			padding-bottom: 5rem;
		}
	}
}
.quickinfo_wrap{
	.pagelinkright{
		width: 100%;
	}
	.downloadright{
		width: 100%;
		margin-left: 0;
	}
}
.dopadding{
	padding:20px 0;
}
footer{
	.halber{
		width: 100%;
		margin-left: 0!important;
		text-align: center;
		.noh3{
			h3{
				display: none;
			}
		}
	}
}
.google_addr{
	input{
		width: 80%;
		&[type="submit"]{
			width: 20%;
		}
	}
}

.headerbild{
	.headertext{
		right: 1rem;
		h2{
			font-size: 2rem;
			i{
				font-size: 2rem;
			}
			b{
				font-size: 2rem;
			}
		}
	}
}
h1{
	font-size: 2rem;
	b{
		font-size: 2rem;
	}
}
body{
	#mobilemenu{
		.main-menu{
			li.sub{
				a{
					padding: 0;
				}
			}
		}
	}
}
.wrap_kalenderdetail{
	.datum_wrap{
		width: 100%;
		padding-right:0;
	}
	.event_text{
		width: 100%;
		margin-top: 3rem;
		text-align: center;
	}
}
footer{
	.backgroundit{
		#bottomnavi{
			li{
				float: none;
				display: inline;
			}
		}
	}

}

@charset "UTF-8";
/* raleway-300 - latin */
@import url("https://fonts.googleapis.com/css?family=Playfair+Display:400i,400");
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: url("/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/raleway/raleway-v34-latin-300.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ }

/* raleway-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url("/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/raleway/raleway-v34-latin-regular.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ }

/* raleway-600 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: url("/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/raleway/raleway-v34-latin-600.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ }

/* raleway-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url("/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/raleway/raleway-v34-latin-700.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ }

/* raleway-800 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  src: url("/typo3conf/ext/spotwebsite/Resources/Public/Assets/Fonts/raleway/raleway-v34-latin-800.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ }

/*
-------------------------------------------------------------------
- VARIABLES :: Spaces
-------------------------------------------------------------------
*/
/*
-------------------------------------------------------------------
- VARIABLES :: Theme colors
-------------------------------------------------------------------
*/
/*
-------------------------------------------------------------------
- VARIABLES :: Bootstrap colors
-------------------------------------------------------------------
*/
/*
-------------------------------------------------------------------
- VARIABLES :: Links
-------------------------------------------------------------------
*/
/*
-------------------------------------------------------------------
- VARIABLES :: Typography
-------------------------------------------------------------------
*/
/*
-------------------------------------------------------------------
- VARIABLES :: Iconography
-------------------------------------------------------------------
*/
/*
-------------------------------------------------------------------
- VARIABLES :: Components
-------------------------------------------------------------------
*/
/*
-------------------------------------------------------------------
- VARIABLES :: Forms
-------------------------------------------------------------------
*/
/*
-------------------------------------------------------------------
- VARIABLES :: Components
-------------------------------------------------------------------
*/
/*
-------------------------------------------------------------------
- VARIABLES :: Grid system
-------------------------------------------------------------------
*/
/*
-------------------------------------------------------------------
- VARIABLES :: Media queries breakpoints
-------------------------------------------------------------------
*/
/*
-------------------------------------------------------------------
- VARIABLES :: Extra media queries breakpoints
-------------------------------------------------------------------
*/
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0; }

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5; }

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold; }

input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal; }

input[type="file"] {
  display: block; }

input[type="range"] {
  display: block;
  width: 100%; }

select[multiple],
select[size] {
  height: auto; }

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555; }

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
  .form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  .form-control::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #999; }
  .form-control::-webkit-input-placeholder {
    color: #999; }
  .form-control::-ms-expand {
    border: 0;
    background-color: transparent; }
  .form-control[disabled], .form-control[readonly],
  fieldset[disabled] .form-control {
    background-color: #eeeeee;
    opacity: 1; }
  .form-control[disabled],
  fieldset[disabled] .form-control {
    cursor: not-allowed; }

textarea.form-control {
  height: auto; }

input[type="search"] {
  -webkit-appearance: none; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    line-height: 34px; }
  input[type="date"].input-sm,
  .input-group-sm input[type="date"],
  input[type="time"].input-sm,
  .input-group-sm
  input[type="time"],
  input[type="datetime-local"].input-sm,
  .input-group-sm
  input[type="datetime-local"],
  input[type="month"].input-sm,
  .input-group-sm
  input[type="month"] {
    line-height: 30px; }
  input[type="date"].input-lg,
  .input-group-lg input[type="date"],
  input[type="time"].input-lg,
  .input-group-lg
  input[type="time"],
  input[type="datetime-local"].input-lg,
  .input-group-lg
  input[type="datetime-local"],
  input[type="month"].input-lg,
  .input-group-lg
  input[type="month"] {
    line-height: 46px; } }

.form-group {
  margin-bottom: 15px; }

.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px; }
  .radio label,
  .checkbox label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer; }

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9; }

.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px; }

.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer; }

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px; }

input[type="radio"][disabled], input[type="radio"].disabled,
fieldset[disabled] input[type="radio"],
input[type="checkbox"][disabled],
input[type="checkbox"].disabled,
fieldset[disabled]
input[type="checkbox"] {
  cursor: not-allowed; }

.radio-inline.disabled,
fieldset[disabled] .radio-inline,
.checkbox-inline.disabled,
fieldset[disabled]
.checkbox-inline {
  cursor: not-allowed; }

.radio.disabled label,
fieldset[disabled] .radio label,
.checkbox.disabled label,
fieldset[disabled]
.checkbox label {
  cursor: not-allowed; }

.form-control-static {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
  min-height: 34px; }
  .form-control-static.input-lg, .form-control-static.input-sm {
    padding-left: 0;
    padding-right: 0; }

.input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

select.input-sm {
  height: 30px;
  line-height: 30px; }

textarea.input-sm,
select[multiple].input-sm {
  height: auto; }

.form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.form-group-sm select.form-control {
  height: 30px;
  line-height: 30px; }

.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
  height: auto; }

.form-group-sm .form-control-static {
  height: 30px;
  min-height: 32px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 1.5; }

.input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 6px; }

select.input-lg {
  height: 46px;
  line-height: 46px; }

textarea.input-lg,
select[multiple].input-lg {
  height: auto; }

.form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 6px; }

.form-group-lg select.form-control {
  height: 46px;
  line-height: 46px; }

.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
  height: auto; }

.form-group-lg .form-control-static {
  height: 46px;
  min-height: 38px;
  padding: 11px 16px;
  font-size: 18px;
  line-height: 1.33333; }

.has-feedback {
  position: relative; }
  .has-feedback .form-control {
    padding-right: 42.5px; }

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none; }

.input-lg + .form-control-feedback,
.input-group-lg + .form-control-feedback,
.form-group-lg .form-control + .form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px; }

.input-sm + .form-control-feedback,
.input-group-sm + .form-control-feedback,
.form-group-sm .form-control + .form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px; }

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #3c763d; }

.has-success .form-control {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .has-success .form-control:focus {
    border-color: #2b542c;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168; }

.has-success .input-group-addon {
  color: #3c763d;
  border-color: #3c763d;
  background-color: #dff0d8; }

.has-success .form-control-feedback {
  color: #3c763d; }

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #8a6d3b; }

.has-warning .form-control {
  border-color: #8a6d3b;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .has-warning .form-control:focus {
    border-color: #66512c;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b; }

.has-warning .input-group-addon {
  color: #8a6d3b;
  border-color: #8a6d3b;
  background-color: #fcf8e3; }

.has-warning .form-control-feedback {
  color: #8a6d3b; }

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #a94442; }

.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .has-error .form-control:focus {
    border-color: #843534;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483; }

.has-error .input-group-addon {
  color: #a94442;
  border-color: #a94442;
  background-color: #f2dede; }

.has-error .form-control-feedback {
  color: #a94442; }

.has-feedback label ~ .form-control-feedback {
  top: 25px; }

.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0; }

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #404040; }

@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .form-inline .form-control-static {
    display: inline-block; }
  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle; }
    .form-inline .input-group .input-group-addon,
    .form-inline .input-group .input-group-btn,
    .form-inline .input-group .form-control {
      width: auto; }
  .form-inline .input-group > .form-control {
    width: 100%; }
  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .radio,
  .form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle; }
    .form-inline .radio label,
    .form-inline .checkbox label {
      padding-left: 0; }
  .form-inline .radio input[type="radio"],
  .form-inline .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0; }
  .form-inline .has-feedback .form-control-feedback {
    top: 0; } }

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px; }

.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 27px; }

.form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px; }
  .form-horizontal .form-group:before, .form-horizontal .form-group:after {
    content: " ";
    display: table; }
  .form-horizontal .form-group:after {
    clear: both; }

@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 7px; } }

.form-horizontal .has-feedback .form-control-feedback {
  right: 15px; }

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 18px; } }

@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 12px; } }

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .btn:hover, .btn:focus, .btn.focus {
    color: #333;
    text-decoration: none; }
  .btn:active, .btn.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn.disabled, .btn[disabled],
  fieldset[disabled] .btn {
    cursor: not-allowed;
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none; }

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none; }

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc; }
  .btn-default:focus, .btn-default.focus {
    color: #333;
    background-color: #e6e6e6;
    border-color: #8c8c8c; }
  .btn-default:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad; }
  .btn-default:active, .btn-default.active,
  .open > .btn-default.dropdown-toggle {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad; }
    .btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus,
    .open > .btn-default.dropdown-toggle:hover,
    .open > .btn-default.dropdown-toggle:focus,
    .open > .btn-default.dropdown-toggle.focus {
      color: #333;
      background-color: #d4d4d4;
      border-color: #8c8c8c; }
  .btn-default:active, .btn-default.active,
  .open > .btn-default.dropdown-toggle {
    background-image: none; }
  .btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled.focus, .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled].focus,
  fieldset[disabled] .btn-default:hover,
  fieldset[disabled] .btn-default:focus,
  fieldset[disabled] .btn-default.focus {
    background-color: #fff;
    border-color: #ccc; }
  .btn-default .badge {
    color: #fff;
    background-color: #333; }

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #286090;
    border-color: #122b40; }
  .btn-primary:hover {
    color: #fff;
    background-color: #286090;
    border-color: #204d74; }
  .btn-primary:active, .btn-primary.active,
  .open > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #286090;
    border-color: #204d74; }
    .btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:hover, .btn-primary.active:focus, .btn-primary.active.focus,
    .open > .btn-primary.dropdown-toggle:hover,
    .open > .btn-primary.dropdown-toggle:focus,
    .open > .btn-primary.dropdown-toggle.focus {
      color: #fff;
      background-color: #204d74;
      border-color: #122b40; }
  .btn-primary:active, .btn-primary.active,
  .open > .btn-primary.dropdown-toggle {
    background-image: none; }
  .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus,
  fieldset[disabled] .btn-primary:hover,
  fieldset[disabled] .btn-primary:focus,
  fieldset[disabled] .btn-primary.focus {
    background-color: #337ab7;
    border-color: #2e6da4; }
  .btn-primary .badge {
    color: #337ab7;
    background-color: #fff; }

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #449d44;
    border-color: #255625; }
  .btn-success:hover {
    color: #fff;
    background-color: #449d44;
    border-color: #398439; }
  .btn-success:active, .btn-success.active,
  .open > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #449d44;
    border-color: #398439; }
    .btn-success:active:hover, .btn-success:active:focus, .btn-success:active.focus, .btn-success.active:hover, .btn-success.active:focus, .btn-success.active.focus,
    .open > .btn-success.dropdown-toggle:hover,
    .open > .btn-success.dropdown-toggle:focus,
    .open > .btn-success.dropdown-toggle.focus {
      color: #fff;
      background-color: #398439;
      border-color: #255625; }
  .btn-success:active, .btn-success.active,
  .open > .btn-success.dropdown-toggle {
    background-image: none; }
  .btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled].focus,
  fieldset[disabled] .btn-success:hover,
  fieldset[disabled] .btn-success:focus,
  fieldset[disabled] .btn-success.focus {
    background-color: #5cb85c;
    border-color: #4cae4c; }
  .btn-success .badge {
    color: #5cb85c;
    background-color: #fff; }

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #31b0d5;
    border-color: #1b6d85; }
  .btn-info:hover {
    color: #fff;
    background-color: #31b0d5;
    border-color: #269abc; }
  .btn-info:active, .btn-info.active,
  .open > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #31b0d5;
    border-color: #269abc; }
    .btn-info:active:hover, .btn-info:active:focus, .btn-info:active.focus, .btn-info.active:hover, .btn-info.active:focus, .btn-info.active.focus,
    .open > .btn-info.dropdown-toggle:hover,
    .open > .btn-info.dropdown-toggle:focus,
    .open > .btn-info.dropdown-toggle.focus {
      color: #fff;
      background-color: #269abc;
      border-color: #1b6d85; }
  .btn-info:active, .btn-info.active,
  .open > .btn-info.dropdown-toggle {
    background-image: none; }
  .btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled.focus, .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled].focus,
  fieldset[disabled] .btn-info:hover,
  fieldset[disabled] .btn-info:focus,
  fieldset[disabled] .btn-info.focus {
    background-color: #5bc0de;
    border-color: #46b8da; }
  .btn-info .badge {
    color: #5bc0de;
    background-color: #fff; }

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236; }
  .btn-warning:focus, .btn-warning.focus {
    color: #fff;
    background-color: #ec971f;
    border-color: #985f0d; }
  .btn-warning:hover {
    color: #fff;
    background-color: #ec971f;
    border-color: #d58512; }
  .btn-warning:active, .btn-warning.active,
  .open > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #ec971f;
    border-color: #d58512; }
    .btn-warning:active:hover, .btn-warning:active:focus, .btn-warning:active.focus, .btn-warning.active:hover, .btn-warning.active:focus, .btn-warning.active.focus,
    .open > .btn-warning.dropdown-toggle:hover,
    .open > .btn-warning.dropdown-toggle:focus,
    .open > .btn-warning.dropdown-toggle.focus {
      color: #fff;
      background-color: #d58512;
      border-color: #985f0d; }
  .btn-warning:active, .btn-warning.active,
  .open > .btn-warning.dropdown-toggle {
    background-image: none; }
  .btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled].focus,
  fieldset[disabled] .btn-warning:hover,
  fieldset[disabled] .btn-warning:focus,
  fieldset[disabled] .btn-warning.focus {
    background-color: #f0ad4e;
    border-color: #eea236; }
  .btn-warning .badge {
    color: #f0ad4e;
    background-color: #fff; }

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c9302c;
    border-color: #761c19; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925; }
  .btn-danger:active, .btn-danger.active,
  .open > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925; }
    .btn-danger:active:hover, .btn-danger:active:focus, .btn-danger:active.focus, .btn-danger.active:hover, .btn-danger.active:focus, .btn-danger.active.focus,
    .open > .btn-danger.dropdown-toggle:hover,
    .open > .btn-danger.dropdown-toggle:focus,
    .open > .btn-danger.dropdown-toggle.focus {
      color: #fff;
      background-color: #ac2925;
      border-color: #761c19; }
  .btn-danger:active, .btn-danger.active,
  .open > .btn-danger.dropdown-toggle {
    background-image: none; }
  .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled].focus,
  fieldset[disabled] .btn-danger:hover,
  fieldset[disabled] .btn-danger:focus,
  fieldset[disabled] .btn-danger.focus {
    background-color: #d9534f;
    border-color: #d43f3a; }
  .btn-danger .badge {
    color: #d9534f;
    background-color: #fff; }

.btn-link {
  color: #337ab7;
  font-weight: normal;
  border-radius: 0; }
  .btn-link, .btn-link:active, .btn-link.active, .btn-link[disabled],
  fieldset[disabled] .btn-link {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active {
    border-color: transparent; }
  .btn-link:hover, .btn-link:focus {
    color: #23527c;
    text-decoration: underline;
    background-color: transparent; }
  .btn-link[disabled]:hover, .btn-link[disabled]:focus,
  fieldset[disabled] .btn-link:hover,
  fieldset[disabled] .btn-link:focus {
    color: #777777;
    text-decoration: none; }

.btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 6px; }

.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.btn-block {
  display: block;
  width: 100%; }

.btn-block + .btn-block {
  margin-top: 5px; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

#kekschecker {
  position: fixed;
  border: 1px solid rgba(0, 0, 0, 0.2);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1400px;
  z-index: 9999999;
  padding: 2em;
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  display: none; }
  #kekschecker > p {
    margin: 0 0 20px; }

#kekschecker .button {
  height: 3em;
  line-height: 3em;
  text-align: center;
  display: inline-block;
  background: #ffc125;
  color: #000000;
  border: 1px solid #000000;
  padding: 0 2em; }

#kekschecker .button:hover {
  background: #000000;
  color: #ffc125;
  border-color: #ffc125; }

.cookieright {
  float: right; }

#infotext {
  display: none; }

#infotext.show {
  display: block;
  position: fixed;
  overflow: hidden;
  overflow-y: scroll;
  z-index: 999999999999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.95); }

#infotext.show > div {
  background: #ffffff;
  padding: 4em 2em;
  max-width: 800px;
  margin: auto;
  float: none;
  overflow: visible;
  position: relative; }
  #infotext.show > div > * {
    float: none; }

#closer {
  position: absolute;
  top: 20px;
  right: 20px;
  background: inherit;
  border-radius: 1em;
  border: 1px solid;
  width: 2em;
  height: 2em;
  line-height: 2em;
  text-align: center;
  cursor: pointer;
  cursor: hand; }

#showlegal {
  text-decoration: underline; }

#infotext h2, #infotext h3, #infotext h4, #infotext h5, #disclaimershow h2, #disclaimershow h3, #disclaimershow h4, #disclaimershow h5 {
  padding: 0;
  color: #4c4c4c; }

#infotext h2, #disclaimershow h2 {
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 25px 0 15px 0; }

#infotext h3, #disclaimershow h3 {
  margin: 20px 0 10px 0; }

#infotext h4, #disclaimershow h4 {
  margin: 10px 0 5px 0; }

#infotext h5, #disclaimershow h5 {
  margin: 5px 0 3px 0; }

#infotext ul, #disclaimershow ul {
  margin: 10px 0 20px 15px;
  padding: 0; }
  #infotext ul li, #disclaimershow ul li {
    list-style-type: disc;
    margin-left: 30px;
    clear: both; }
    #infotext ul li p, #disclaimershow ul li p {
      margin: 0; }

#disclaimershow a,
#infotext a {
  color: #ffc125;
  font-weight: 600; }
  #disclaimershow a:hover,
  #infotext a:hover {
    color: #000000;
    border-bottom: 3px solid rgba(255, 193, 37, 0.4); }

#kekschecker a {
  color: #ffc125;
  font-weight: 600; }
  #kekschecker a:hover {
    border-bottom: 3px solid rgba(255, 193, 37, 0.4); }

@media (max-width: 500px) {
  span.button {
    margin-bottom: 1em;
    clear: both;
    margin: 0.5em 0;
    width: 100%;
    float: none; }
  #kekschecker p, #kekschecker a {
    line-height: 1.3em; } }

/*
================================================================
                    CSS Document
          (c) SPOT Werbung Duri Zisler
                         2016
================================================================
*/
/*
================
DEFINITIONEN:
================
*/
*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Raleway";
  font-size: 15px;
  line-height: 1.6em; }

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, textarea, p, blockquote, th, td, label, fieldset, legend, header, nav, section, article, aside, footer, hgroup, time, figure {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  word-wrap: break-word; }

a {
  outline: none;
  text-decoration: none; }

a:hover {
  text-decoration: none; }

fieldset, img {
  border: 0;
  /*width:100%;*/
  float: left; }

address, caption, cite, code, dfn, em, th, var {
  font-style: normal;
  font-weight: normal; }

ol, ul {
  list-style: none;
  float: left;
  width: 100%; }

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
  float: left;
  width: 100%; }

sup,
sub {
  font-size: 0.7em; }

p, div, table, fieldset, form, header, nav, section, article, aside, footer, hgroup, time, input, figure {
  float: left;
  width: 100%;
  max-width: 100%; }

input[type="checkbox"],
input[type="radio"] {
  width: auto; }

.container {
  float: none; }

.row,
.mfp-container div,
#ui-datepicker-div div,
#TSFE_ADMIN_PANEL_FORM {
  width: auto;
  float: none; }

.mfp-container .mfp-bottom-bar {
  width: 100%; }

.gmap-canvas-content.canvas-content div,
.pp_pic_holder p,
.pp_pic_holder div,
#map_canvas div,
.at3winwrapper div {
  float: none;
  width: auto; }

.gmap-canvas-content.canvas-content .gm-style-iw,
#map_canvas .gm-style-iw {
  height: auto !important; }

.gmap-canvas-content.canvas-content .gm-style-iw div,
#map_canvas .gm-style-iw div {
  overflow: hidden !important; }

.gmap-canvas-content.canvas-content img,
#map_canvas img {
  max-width: none; }

.gmap-canvas-content.canvas-content .google_addr div,
#map_canvas .google_addr div {
  float: left; }

.google_addr {
  background: #ffffff;
  padding: 2rem; }
  .google_addr input {
    width: 50%; }

.mfp-wrap {
  z-index: 90000 !important; }

.mfp-iframe-holder .mfp-content {
  max-width: 98% !important; }

#map_canvas p, #map_canvas div, #map_canvas table, #map_canvas fieldset, #map_canvas form, #map_canvas header, #map_canvas nav, #map_canvas section, #map_canvas article, #map_canvas aside, #map_canvas footer, #map_canvas hgroup, #map_canvas time, #map_canvas input, #map_canvas figure {
  max-width: none; }

body {
  font-family: "Raleway";
  font-size: 15px;
  line-height: 1.3333em;
  color: #646469;
  background: #f4f4f4;
  /*-webkit-text-stroke: 0.1px;*/ }

/*
div{
     max-width: 1980px;
}
*/
.centerit {
  width: 1400px;
  float: none;
  margin: 0 auto; }

.noblock {
  float: none !important;
  width: auto !important;
  display: inline !important; }

.bold {
  font-weight: bold; }

.block {
  float: left;
  width: 100%;
  display: block; }

.center {
  text-align: center; }

.right {
  float: right;
  text-align: right; }

.textright {
  text-align: right; }
  .textright * {
    display: inline-block;
    float: none !important; }

.autowidth {
  width: auto; }

.relative {
  position: relative; }

.smallfont {
  font-size: 0.9em; }

.spalten,
.cols2 {
  -moz-column-count: 2;
  -webkit-column-count: 2;
  column-count: 2;
  -moz-column-gap: 1.5em;
  -webkit-column-gap: 1.5em;
  column-gap: 1.5em; }

.cols3 {
  -moz-column-count: 3;
  -webkit-column-count: 3;
  column-count: 3;
  -moz-column-gap: 1.5em;
  -webkit-column-gap: 1.5em;
  column-gap: 1.5em; }

.drittel {
  width: 32%;
  min-height: 1px; }

.drittel + .drittel {
  margin-left: 2%; }

.drittel:nth-of-type(3n+4) {
  margin-left: 0; }

.zweidrittel {
  width: 66%;
  min-height: 1px; }

.zweidrittel + .drittel,
.drittel + .zweidrittel {
  margin-left: 2%; }

.halber,
.m_halber {
  width: 49%;
  min-height: 1px;
  margin-left: 0; }

.halber:nth-child(even) {
  margin-left: 2%; }

/*
.halber + .halber{
     margin-left:2%;
}
.halber:nth-of-type(n+3){
     margin-left:0;
}
div[class~=halber]:nth-of-type(n+3){
     margin-left:0;
}
*/
.zweihalber + .zweihalber {
  margin-top: 3em; }

.halbernogap {
  width: 50%; }

img,
object {
  max-width: 100%;
  height: auto; }

.dogrow {
  width: 100%;
  height: auto; }

button {
  float: left; }

.viertel {
  width: 22%;
  min-height: 1px; }

.viertel + .viertel {
  margin-left: 4%; }

.issuuembed {
  width: 100% !important;
  height: auto !important;
  position: relative;
  padding-bottom: 62.25%;
  overflow: hidden; }

.issuuembed object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.float {
  float: left; }

.bold {
  font-weight: bold; }

.nodesktop,
.nodisplay {
  display: none; }

.noshow {
  max-height: 0;
  overflow: hidden; }

p + p {
  margin-top: 0.8em; }

.mobilonly {
  display: none; }

.nofloat {
  float: none; }

.middle {
  vertical-align: middle; }

svg {
  max-height: 100%;
  height: auto; }

.paddingit {
  padding: 0 8.2%; }

.backgroundit {
  background: #ffffff; }

.open .hamburger {
  background: none; }
  .open .hamburger:before {
    transform: rotate(-45deg);
    top: -1em; }
  .open .hamburger:after {
    transform: rotate(45deg);
    bottom: 0.5em; }

.hamburger {
  display: none;
  z-index: 900000;
  width: 30px;
  height: 3px;
  background: #898989;
  position: relative; }
  .hamburger:before {
    position: absolute;
    top: -0.5em;
    content: "\00a0";
    border-top: 3px solid #898989;
    display: block;
    width: 100%;
    transition: all 0.5s ease 0s; }
  .hamburger:after {
    position: absolute;
    bottom: -0.5em;
    content: "\00a0";
    border-bottom: 3px solid #898989;
    display: block;
    width: 100%;
    transition: all 0.5s ease 0s; }

/*
}
.menu-toggle {
  color: white;
  text-decoration: none;
  background:red;
}

.menu-label {
  margin-bottom:-.2em;
  display:inline-block;
  vertical-align: middle;
}

.menu-icon + .menu-label {
  margin-left:.2em;
}


.menu-icon {
  cursor: pointer;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: .5em;
  color: inherit;
  background: currentColor;
  border-radius: .5em;
  height: .4em;
  width: 2.6em;
}

.menu-icon:before,
.menu-icon:after {
  border-radius: .5em;
  height: .4em;
  width: 100%;
  left: 0;
  background: currentColor;
  position: absolute;
  display: block;
  content: '';
}
.menu-icon:before {
  top: -.8em; 
}
.menu-icon:after {
  top: .8em;
}

.menu-icon,
.menu-icon:before,
.menu-icon:after {
  transition: all .5s ease-in-out;
}


.open .menu-icon {
  background-color: transparent;
  transform: rotate(45deg) translate(0%, -50%);
}
.open .menu-icon:before,
.open .menu-icon:after {
  top: 0em;
}
.open .menu-icon:before {
  transform: rotate(180deg);
}
.open .menu-icon:after {
  transform: rotate(270deg);
}
*/
/*
-------------------------------------------------------------------
- MIXIN :: Font awesome icons
-------------------------------------------------------------------
*/
/*
-------------------------------------------------------------------
- MIXIN :: Buttons
-------------------------------------------------------------------
*/
/*
-------------------------------------------------------------------
- MIXIN :: Hover darken colors
-------------------------------------------------------------------
*/
/*
-------------------------------------------------------------------
- MIXIN :: Hover darken colors
-------------------------------------------------------------------
*/
/*
-------------------------------------------------------------------
- OVERRIDE :: Buttons
-------------------------------------------------------------------
*/
.btn-primary {
  display: inline-block;
  color: #ffffff;
  background-color: #ffc125;
  border-color: #ffc125;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out; }
  .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active,
  .open .dropdown-toggle .btn-primary {
    color: #ffffff;
    background-color: #eca900;
    border-color: #eca900; }
  .btn-primary:active, .btn-primary.active,
  .open .dropdown-toggle .btn-primary {
    background-image: none; }
  .btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled]:active, .btn-primary[disabled].active,
  fieldset[disabled] .btn-primary,
  fieldset[disabled] .btn-primary:hover,
  fieldset[disabled] .btn-primary:focus,
  fieldset[disabled] .btn-primary:active,
  fieldset[disabled] .btn-primary.active {
    background-color: #ffc125;
    border-color: #ffc125; }
  .btn-primary .badge {
    color: #ffc125;
    background-color: #fff; }

/*
-------------------------------------------------------------------
- OVERRIDE :: Containers, Grids and Columns
-------------------------------------------------------------------
*/
/*
.container {
    
    @media (min-width: $screen-xl-min) {
        width: $container-xl;
    }
    
    @media (min-width: $screen-xxl-min) {
        width: $container-xxl;
    }
    
    @media (min-width: $screen-ul-min) {
        width: $container-ul;
    }
}

@media (min-width: $screen-xl-min) {
	.make-grid(xl);
}
	
@media (min-width: $screen-xxl-min) {
	.make-grid(xxl);
}

@media (min-width: $screen-ul-min) {
	.make-grid(ul);
}

// Generate extra large columns
@mixin make-xl-column($columns, $gutter: $grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-left:  ($gutter / 2);
	padding-right: ($gutter / 2);
	
	@media (min-width: $screen-xl-min) {
		float: left;
		width: percentage(($columns / $grid-columns));
	}
}

@mixin make-xl-column-offset($columns) {
	@media (min-width: $screen-xl-min) {
		margin-left: percentage(($columns / $grid-columns));
	}
}
@mixin make-xl-column-push($columns) {
	@media (min-width: $screen-xl-min) {
		left: percentage(($columns / $grid-columns));
	}
}
@mixin make-xl-column-pull($columns) {
	@media (min-width: $screen-xl-min) {
		right: percentage(($columns / $grid-columns));
	}
}

// Generate extra extra large columns
@mixin make-xxl-column($columns, $gutter: $grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-left:  ($gutter / 2);
	padding-right: ($gutter / 2);
	
	@media (min-width: $screen-xxl-min) {
		float: left;
		width: percentage(($columns / $grid-columns));
	}
}
@mixin make-xxl-column-offset($columns) {
	@media (min-width: $screen-xxl-min) {
		margin-left: percentage(($columns / $grid-columns));
	}
}
@mixin make-xxl-column-push($columns) {
	@media (min-width: $screen-xxl-min) {
		left: percentage(($columns / $grid-columns));
	}
}
@mixin make-xxl-column-pull($columns) {
	@media (min-width: $screen-xxl-min) {
		right: percentage(($columns / $grid-columns));
	}
}

// Generate ultra large columns
@mixin make-ul-column($columns, $gutter: $grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-left:  ($gutter / 2);
	padding-right: ($gutter / 2);
	
	@media (min-width: $screen-ul-min) {
		float: left;
		width: percentage(($columns / $grid-columns));
	}
}
@mixin make-ul-column-offset($columns) {
	@media (min-width: $screen-ul-min) {
		margin-left: percentage(($columns / $grid-columns));
	}
}
@mixin make-ul-column-push($columns) {
	@media (min-width: $screen-ul-min) {
		left: percentage(($columns / $grid-columns));
	}
}
@mixin make-ul-column-pull($columns) {
	@media (min-width: $screen-ul-min) {
		right: percentage(($columns / $grid-columns));
	}
}

// Large grid
// -> Columns, offsets, pushes, and pulls for the large desktop device range.
@media (min-width: $screen-xl-min) {
	@include make-grid(xl);
}

// Large grid
// -> Columns, offsets, pushes, and pulls for the large desktop device range.
media (min-width: $screen-xxl-min) {
  @include make-grid(xxl);
}

// Large grid
// -> Columns, offsets, pushes, and pulls for the large desktop device range.
@media (min-width: $screen-ul-min) {
  @include make-grid(ul);
}
*/
header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300000; }
  header .paddingit {
    padding-bottom: 2rem; }

#maincontentwrap {
  margin-top: 141px; }

#newordermobilecontent {
  display: none; }

#logo {
  width: 25%;
  margin-top: 3.2rem; }
  #logo a svg {
    width: 290px; }

#header_right {
  width: 75%; }
  #header_right #langmenu {
    float: none;
    width: auto;
    display: inline-block;
    height: 2.333rem;
    line-height: 2.333rem;
    margin-left: 1em;
    margin-right: 4em;
    margin-top: 2rem;
    font-weight: 700;
    min-width: 75px; }
    #header_right #langmenu a {
      color: rgba(0, 0, 0, 0.7); }
      #header_right #langmenu a.active {
        opacity: 0.45; }
  #header_right nav {
    width: auto;
    float: right; }

#bottomservicenavi,
#servicenavi_top {
  width: auto;
  display: inline-block;
  float: none;
  background: rgba(0, 0, 0, 0.7); }
  #bottomservicenavi a,
  #servicenavi_top a {
    display: inline-block;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 700;
    color: #ffffff;
    padding: 10px 30px;
    float: left;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s; }
    #bottomservicenavi a:before,
    #servicenavi_top a:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 193, 37, 0.8);
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
      transition: transform 0.5s ease-out; }
    #bottomservicenavi a#topservice_9,
    #servicenavi_top a#topservice_9 {
      background: #ffc125; }
      #bottomservicenavi a#topservice_9:before,
      #servicenavi_top a#topservice_9:before {
        background: rgba(0, 0, 0, 0.8); }
    #bottomservicenavi a#topservice_70,
    #servicenavi_top a#topservice_70 {
      background: #416ca7; }
      #bottomservicenavi a#topservice_70:before,
      #servicenavi_top a#topservice_70:before {
        background: rgba(0, 0, 0, 0.8); }
    #bottomservicenavi a#topservice_10, #bottomservicenavi a#topservice_69,
    #servicenavi_top a#topservice_10,
    #servicenavi_top a#topservice_69 {
      background: rgba(0, 0, 0, 0.7); }
    #bottomservicenavi a:hover:before,
    #servicenavi_top a:hover:before {
      transform: scaleY(1);
      transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66); }

.main-menu {
  padding-top: 2.3333rem;
  width: auto;
  float: right; }
  .main-menu > li {
    display: inline-block;
    float: left;
    padding: 0 0.75em; }
    .main-menu > li:last-child {
      padding-right: 0; }
    .main-menu > li > a {
      display: block;
      font-size: 1.2rem;
      color: #000000;
      font-weight: 700;
      transition: all 0.3s ease;
      position: relative;
      text-transform: uppercase; }
      .main-menu > li > a:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #ffc125;
        visibility: hidden;
        -webkit-transform: scaleX(0);
        -ms-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s; }
      .main-menu > li > a.current, .main-menu > li > a.open, .main-menu > li > a:hover {
        color: #ffc125; }
        .main-menu > li > a.current:after, .main-menu > li > a.open:after, .main-menu > li > a:hover:after {
          visibility: visible;
          -webkit-transform: scaleX(1);
          -ms-transform: scaleX(1);
          transform: scaleX(1); }
    .main-menu > li.sub .paddingit.backgroundit {
      z-index: 1;
      transition: all 1s ease-out 0s;
      max-height: 0;
      overflow: hidden;
      position: absolute;
      left: 0;
      top: calc(100%-3rem);
      background: #ffffff;
      width: 100%; }
      .main-menu > li.sub .paddingit.backgroundit ul {
        padding: 4rem 0 0 0; }
        .main-menu > li.sub .paddingit.backgroundit ul li {
          float: left;
          width: 33%;
          text-align: left;
          height: 7rem; }
          .main-menu > li.sub .paddingit.backgroundit ul li a {
            font-family: "Playfair Display";
            font-weight: 400;
            color: rgba(0, 0, 0, 0.7);
            display: inline-block;
            float: left;
            transition: all 0.5s ease-out 0s;
            font-style: normal; }
            .main-menu > li.sub .paddingit.backgroundit ul li a b {
              font-family: "Raleway";
              font-weight: 700;
              display: block;
              text-transform: uppercase; }
            .main-menu > li.sub .paddingit.backgroundit ul li a .liner {
              display: block;
              width: 30%;
              border-bottom: 1px solid #979797;
              transition: all 0.3s ease-in-out 0s;
              padding-top: 1rem; }
            .main-menu > li.sub .paddingit.backgroundit ul li a:hover {
              color: #000000; }
              .main-menu > li.sub .paddingit.backgroundit ul li a:hover .liner {
                width: 100%; }
    .main-menu > li.sub.hover .paddingit.backgroundit, .main-menu > li.sub:hover .paddingit.backgroundit {
      max-height: none;
      z-index: 90000; }

body #mobilemenu {
  background: #ffffff;
  height: 0;
  max-height: 0;
  position: fixed;
  overflow: hidden;
  transition: all 0.5s ease-out 0s;
  z-index: 500000;
  top: 0; }
  body #mobilemenu > .main-menu {
    top: 0;
    width: 100%;
    transition: all 0.5s ease-out 0s;
    text-align: center; }
    body #mobilemenu > .main-menu > li {
      float: none;
      display: inline-block; }
  body #mobilemenu .main-menu > li.sub .paddingit.backgroundit {
    padding: 0 20%; }
    body #mobilemenu .main-menu > li.sub .paddingit.backgroundit li {
      width: 50%;
      text-align: center; }
      body #mobilemenu .main-menu > li.sub .paddingit.backgroundit li a {
        float: none; }
        body #mobilemenu .main-menu > li.sub .paddingit.backgroundit li a .liner {
          margin: auto; }
  body #mobilemenu .hamburger {
    position: absolute;
    top: 60px;
    right: 30px;
    display: block; }

body.open {
  overflow: hidden; }
  body.open #mobilemenu {
    height: 100%;
    max-height: 2500px;
    overflow-y: scroll; }

#uid1 .content_wrap {
  padding: 0.333333rem 0 0 0; }

#largecontenthere {
  display: none; }

.content-main .backgroundit {
  padding: 0 1rem 3rem 1rem; }

.content_wrap {
  width: 68%;
  padding-right: 2rem;
  padding-top: 3rem; }

.quickinfo_wrap {
  width: 32%; }
  .quickinfo_wrap *:first-child .righttitel {
    background: #ffc125;
    margin-top: -5.8rem;
    position: relative;
    z-index: 3000; }

.righttitel {
  text-align: center;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  font-weight: 700;
  padding: 2.1rem 0; }

.rightlink {
  position: relative;
  box-shadow: none;
  transition: box-shadow 1s;
  /*
	&:before{
		content: "";
	    position: absolute;
	    z-index: 0;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    background: rgba($theme-base-color,0.8);
	    -webkit-transform: scaleX(0);
	    transform: scaleX(0);
	    -webkit-transform-origin: 0 50%;
	    transform-origin: 0 50%;
	    transition: all 0.5s ease-out 0s;
	}
	*/ }
  .rightlink.svgright svg {
    float: right;
    height: 13px; }
  .rightlink.svgleft svg {
    float: left;
    width: 15%;
    padding-right: 1rem;
    padding-top: 0.5rem;
    height: 25px; }
  .rightlink.svgleft .wrap_files,
  .rightlink.svgleft .wrap_kalender {
    float: left;
    width: 85%; }
    .rightlink.svgleft .wrap_files .datum_kalender_von,
    .rightlink.svgleft .wrap_kalender .datum_kalender_von {
      font-size: 1rem;
      font-weight: 800;
      line-height: 1.1em; }
    .rightlink.svgleft .wrap_files h3,
    .rightlink.svgleft .wrap_kalender h3 {
      font-size: 1rem;
      text-transform: none;
      line-height: 1.1em;
      font-weight: 400;
      color: #929292;
      padding: 0; }
  .rightlink:nth-child(even) {
    background: #f4f4f4; }
  .rightlink a {
    padding: 1.8rem 2rem;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 700;
    display: block;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
    transition: all 0.5s ease-out 0s;
    float: left; }
    .rightlink a svg {
      vertical-align: middle;
      height: 16px;
      width: auto;
      transition: all 0.3s ease-in-out 0s; }
  .rightlink:hover {
    box-shadow: 0 0 3px #515151;
    z-index: 300;
    /*
		&:before{
			transform: scaleX(1);
		}
		svg{
			height: 20px;
			width:auto;
			//transform:rotate(-90deg);
			//transform:translate(30px,30px	);
		}
		*/ }
    .rightlink:hover a {
      color: #000000; }

.dopadding {
  padding: 20px 200px 20px 200px; }

.bottommarginer {
  margin-bottom: 20px; }

.google_addr {
  line-height: 1.3rem; }
  .google_addr input {
    height: 3rem;
    line-height: 3rem;
    padding: 0rem 0.5rem; }
    .google_addr input[type="submit"] {
      background: #ffc125;
      color: #ffffff;
      border: none; }

p + figure {
  padding-top: 3rem; }

figure {
  padding: 1em 0 2em 0; }
  figure figcaption,
  figure a figcaption {
    color: #929292;
    font-weight: normal;
    padding-top: 1.5rem;
    font-size: 0.8666666667rem;
    float: left; }
  figure + h2,
  figure + h3 {
    padding-top: 0; }

footer .backgroundit {
  background: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  padding: 0 1rem 50px 1rem; }
  footer .backgroundit * {
    font-size: 0.8666666667rem; }
  footer .backgroundit a {
    color: #ffffff; }
    footer .backgroundit a:hover {
      color: rgba(255, 255, 255, 0.8); }
  footer .backgroundit h3 {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.1333333333;
    margin: 2.1333rem 0 0.8rem 0;
    font-size: 0.9333333333rem;
    color: #ffffff; }
  footer .backgroundit #bottomnavi {
    margin-top: 2rem; }
    footer .backgroundit #bottomnavi li {
      float: left;
      width: auto; }
      footer .backgroundit #bottomnavi li a {
        font-weight: 700;
        margin-right: 25px; }
  footer .backgroundit .socialmedia {
    margin-top: 2em; }
    footer .backgroundit .socialmedia svg {
      height: 18px;
      width: auto;
      margin-right: 1em; }
      footer .backgroundit .socialmedia svg g {
        fill: #ffffff; }
    footer .backgroundit .socialmedia a:hover svg g {
      fill: rgba(255, 255, 255, 0.8); }

/*
------------------------------------------------------------------------------
Headerbild
------------------------------------------------------------------------------
*/
.headerbild .headertext {
  z-index: 90;
  position: absolute;
  right: 8.2%;
  bottom: 25%;
  text-align: right;
  width: auto; }
  .headerbild .headertext h3 {
    color: #ffffff;
    font-family: "Raleway";
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 0.0555555556em;
    text-transform: uppercase; }
  .headerbild .headertext h2 {
    padding-top: 0;
    color: #ffffff;
    font-size: 4rem;
    line-height: 1em;
    /* Text style for "Platz zum" */
    font-style: normal;
    font-family: "Raleway";
    font-weight: 300; }
    .headerbild .headertext h2 br {
      height: 1px; }
    .headerbild .headertext h2 i {
      font-size: 4rem;
      font-family: "Playfair Display";
      line-height: 1em; }
    .headerbild .headertext h2 b {
      line-height: 1em;
      font-weight: 700;
      font-size: 4rem; }

.headerbild:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3); }

/*
------------------------------------------------------------------------------
Video
------------------------------------------------------------------------------
*/
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0; }
  .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.bottommargin-1 {
  margin-bottom: 5pc; }

/*
------------------------------------------------------------------------------
Content
------------------------------------------------------------------------------
*/
h1 {
  color: rgba(0, 0, 0, 0.7);
  font-family: "Playfair Display";
  font-size: 4rem;
  font-weight: 400;
  line-height: 1em;
  padding-bottom: 3rem;
  font-style: italic; }
  h1 b {
    font-family: "Raleway";
    font-weight: 800;
    font-style: normal;
    font-size: 4rem;
    line-height: 1em; }
  h1 span {
    font-family: "Raleway";
    font-size: 4rem;
    font-weight: 400;
    line-height: 1em;
    font-style: normal; }
  h1 + h2 {
    padding-top: 0; }

h2 {
  color: #4c4c4c;
  font-family: "Playfair Display";
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.1em;
  padding: 1.57em 0 1em 0;
  font-style: italic; }
  h2 + ol,
  h2 + ul {
    padding-top: 0 !important; }
  h2 + h3 {
    padding-top: 0 !important; }

h3 {
  color: #4c4c4c;
  font-size: 1.0666666667em;
  font-weight: 700;
  letter-spacing: 0.0625em;
  text-transform: uppercase;
  padding: 1.2em 0 0.6em 0;
  line-height: 1.5em; }
  h3 + ol,
  h3 + ul {
    padding-top: 0 !important; }

ol + h2,
ol + h3,
ul + h2,
ul + h3 {
  padding-top: 0.5rem !important; }

.content_wrap {
  color: #898989; }
  .content_wrap a {
    color: #ffc125;
    font-weight: 600; }
    .content_wrap a:hover {
      border-bottom: 3px solid rgba(255, 193, 37, 0.4); }
  .content_wrap ol {
    padding: 1rem 0 2rem 2rem;
    list-style-type: none;
    counter-reset: ol-counter; }
    .content_wrap ol li {
      position: relative;
      padding-left: 0.5em; }
      .content_wrap ol li:before {
        position: absolute;
        left: -1em;
        content: counter(ol-counter) ".  ";
        counter-increment: ol-counter;
        font-size: 1.1em;
        font-weight: 600;
        line-height: 1.2em; }
  .content_wrap ul {
    padding: 1rem 0 2rem 1.5rem; }
    .content_wrap ul li {
      list-style: none;
      position: relative; }
      .content_wrap ul li:before {
        content: '\2022';
        line-height: 1.6rem;
        position: absolute;
        left: -18px; }

.homelink {
  /*
	.blau{
		background:#416ca7;
	}
	.rot{
		background:#b43d3e;
	}
	.gelb{
		background:#ffc125;
	}
	.gruen{
		background:#67ac50;
	}
	*/ }
  .homelink .rechts .homelink_bild:first-child {
    display: none; }
  .homelink a {
    display: block;
    float: left;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 0.333333rem;
    position: relative;
    border: 1px solid #f4f4f4;
    transition: 0.2s ease-in-out all 0.05s; }
    .homelink a:hover {
      color: #000000;
      border: 1px solid #898989;
      background: #f4f4f4;
      /*
			.homelink_pfeil{
				transform:scale(3.5);
				position: relative;
				svg{
					transform:scale(0.2941176471);
					position: absolute;
					bottom:3px;
					left:3px;
					transition-property: none;
				}
			}
			*/ }
      .homelink a:hover img {
        opacity: 1;
        transition: 0.2s ease-in-out all 0.2s; }
    .homelink a img {
      opacity: 0.8; }
    .homelink a span {
      display: block;
      float: left;
      height: 100%; }
    .homelink a .home_text {
      width: 34%;
      /*padding:3.5rem 1em 0;*/
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 0.8em;
      /*
			.homelink_pfeil{
				transform-origin: left bottom;
				transition:all 0.5s ease 0s;
				position: absolute;
				bottom:0;
				width:5rem;
				height: 4.98rem;
				line-height: 5rem;
				//background:rgba($black-color,0.15);
				background:#ffc125;
				text-align: center;
				margin-left: -1rem;
				svg{
					width: 14px;
					height: auto;
					vertical-align: middle;
					transition-property: none;
					//position: absolute;
					//bottom:3px;
					//left:3px;
					g{
						fill:$white-color !important;
					}
				}
			}
			*/ }
      .homelink a .home_text .homelink_titel {
        font-size: 1.6666666667rem;
        font-weight: 700;
        line-height: 1.8rem;
        margin-bottom: 1.2rem;
        position: relative;
        z-index: 900; }
      .homelink a .home_text .homelink_text {
        font-weight: 400;
        position: relative;
        z-index: 900; }
      .homelink a .home_text .homelink_pfeil {
        display: none; }
    .homelink a .homelink_bild {
      width: 66%; }

.blau {
  color: #416ca7; }

.rot {
  color: #b43d3e; }

.gelb {
  color: #ffc125; }

.gruen {
  color: #67ac50; }

.googlemaplogo {
  float: left;
  width: 160px;
  height: auto; }

#map_canvas strong {
  margin-top: 2rem; }

#uid44 #map_canvas {
  background: url(/typo3conf/ext/spotwebsite/Resources/Public/Assets/Images/gmap_samedan.png) no-repeat;
  background-size: cover;
  background-position: center center; }

#uid45 #map_canvas {
  background: url(/typo3conf/ext/spotwebsite/Resources/Public/Assets/Images/gmap_poschiavo.png) no-repeat;
  background-size: cover;
  background-position: center center; }

#uid46 #map_canvas {
  background: url(/typo3conf/ext/spotwebsite/Resources/Public/Assets/Images/gmap_scuol.png) no-repeat;
  background-size: cover;
  background-position: center center; }

#uid47 #map_canvas {
  background: url(/typo3conf/ext/spotwebsite/Resources/Public/Assets/Images/gmap_jaura.png) no-repeat;
  background-size: cover;
  background-position: center center; }

#uid1 .quickinfo_wrap h1.c-heading_title {
  display: none; }

.wrap_kalenderdetail {
  padding: 2rem 0; }
  .wrap_kalenderdetail.selected {
    background: #f4f4f4; }
  .wrap_kalenderdetail .datum_wrap {
    width: 25%;
    padding-right: 2rem;
    color: #000000; }
    .wrap_kalenderdetail .datum_wrap .block {
      line-height: 0.3em;
      font-weight: 700; }
    .wrap_kalenderdetail .datum_wrap .datum_kalender_von,
    .wrap_kalenderdetail .datum_wrap .datum_kalender_bis {
      display: block;
      width: 100%;
      font-weight: 700; }
    .wrap_kalenderdetail .datum_wrap .datum_kalender_hour {
      margin-top: 1rem; }
  .wrap_kalenderdetail .event_text {
    width: 75%; }
    .wrap_kalenderdetail .event_text h2 {
      line-height: 1em;
      padding: 0 0 0.4em 0; }
  .wrap_kalenderdetail + .wrap_kalenderdetail {
    border-top: 1px solid rgba(137, 137, 137, 0.5); }

.powermail_form legend {
  margin-bottom: 15px; }

.powermail_form.form-horizontal .control-label {
  text-align: left; }

.powermail_form .products {
  margin-bottom: 15px; }

.powermail_form .powermail_fieldwrap_produkte {
  padding: 5px 0;
  transition: background 0.3s;
  margin-bottom: 0; }
  .powermail_form .powermail_fieldwrap_produkte:hover {
    background: #f4f4f4; }

.spotsocial {
  display: none; }

.item-facebook {
  width: 40%;
  text-align: center;
  position: relative;
  /*
	&:before{
		content: "";
	    position: absolute;
	    z-index: 0;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    background: $theme-base-color;
	    transform: scale(0);
	    transition-property: transform;
	    transition-duration:0.5s;
	    transition-timing-function: ease-out;
	}
	*/
  box-shadow: 0;
  transition: box-shadow 1s; }
  .item-facebook .item-wrap {
    z-index: 30;
    position: relative; }
  .item-facebook:hover {
    box-shadow: 0 0 3px #515151;
    /*
		&:before{
			transform: scale(1);
		}
		*/ }
  .item-facebook .date,
  .item-facebook .profile-name {
    text-transform: uppercase;
    font-size: 0.6666666rem;
    letter-spacing: 0.2rem;
    color: #000000;
    font-weight: 700; }
  .item-facebook .date {
    padding-top: 1rem;
    font-size: 0.666rem; }
  .item-facebook .profile-name {
    padding-bottom: 1rem; }
  .item-facebook .message,
  .item-facebook .description {
    color: rgba(0, 0, 0, 0.7);
    font-size: 0.8rem;
    line-height: 1.4rem;
    padding-top: 1em; }
  .item-facebook .item-wrap {
    padding: 30px 34px; }
  .item-facebook .item-body svg {
    width: 10px; }

.item-instagram {
  width: 20%; }
  .item-instagram .picture {
    overflow: hidden;
    position: relative; }
    .item-instagram .picture img {
      transition: all 0.5s ease; }
    .item-instagram .picture:hover img {
      transform: scale(1.2); }
    .item-instagram .picture svg {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      width: 26px; }
      .item-instagram .picture svg g {
        fill: #ffffff; }

.item:nth-child(4) {
  clear: both; }

#newordercontent {
  margin-top: 2.3333333333rem;
  background: #ffffff; }

@media (max-width: 992px) {
  .nomobile {
    display: none; }
  #mobilemenu .main-menu {
    padding-top: 2.8em; }
  #mobilemenu .sub.open .paddingit.backgroundit, #mobilemenu .sub.hover .paddingit.backgroundit {
    max-height: none; }
  #mobilemenu .sub.hover > a {
    color: #ffc125; }
    #mobilemenu .sub.hover > a:after {
      visibility: visible;
      -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
      transform: scaleX(1); }
  #mobilemenu .sub .paddingit.backgroundit {
    border-top: 1px solid rgba(151, 151, 151, 0.5);
    margin-top: 2em; }
  header .paddingit {
    padding: 0 1.3333rem 1rem; }
  header #logo a svg {
    width: 250px;
    height: auto;
    max-width: 100%; }
  header #header_right {
    margin-top: 3.2rem; }
    header #header_right #header_right_up {
      width: 75%; }
    header #header_right #header_right_down {
      width: 25%;
      float: right; }
      header #header_right #header_right_down .hamburger {
        display: inline-block; }
      header #header_right #header_right_down nav {
        display: none; }
      header #header_right #header_right_down #langmenu {
        margin: 0; }
        header #header_right #header_right_down #langmenu .langwrap {
          margin-top: 0.5em;
          float: left;
          margin-right: 2rem;
          width: 56px;
          position: relative;
          background: #ffffff;
          z-index: 90; }
          header #header_right #header_right_down #langmenu .langwrap.show a {
            display: block; }
          header #header_right #header_right_down #langmenu .langwrap a {
            display: none;
            text-transform: uppercase;
            float: left;
            text-align: center;
            width: 40px;
            color: #4c4c4c !important;
            font-weight: 700;
            opacity: 1; }
            header #header_right #header_right_down #langmenu .langwrap a.active {
              display: block; }
          header #header_right #header_right_down #langmenu .langwrap:after {
            content: url("/typo3conf/ext/spotwebsite/Resources/Public/Assets/Svg/arrow-down.svg");
            margin-top: -1em;
            font-size: 1.2em;
            display: inline-block; }
  .homelink a .home_text {
    padding-top: 1.5rem; }
  .dopadding {
    padding: 20px 3rem; }
  .headerbild .headertext {
    bottom: auto;
    top: 3rem; }
  .content_wrap {
    width: 100%;
    margin-bottom: 2rem; }
  .quickinfo_wrap {
    width: 100%; }
    .quickinfo_wrap *:first-child .righttitel {
      margin-top: 0; }
    .quickinfo_wrap .pagelinkright {
      width: 49%; }
    .quickinfo_wrap .downloadright {
      width: 49%;
      margin-left: 2%; }
  #largecontenthere {
    display: block; }
  .largecontent {
    display: none; }
  .item-facebook .item-wrap {
    padding: 10px; }
    .item-facebook .item-wrap svg {
      width: 7px; }
    .item-facebook .item-wrap .profile-name {
      font-size: 0.8rem;
      padding-bottom: 0.8rem; }
    .item-facebook .item-wrap .message,
    .item-facebook .item-wrap .description {
      font-size: 0.8rem; }
    .item-facebook .item-wrap .date {
      font-size: 0.8rem;
      padding-top: 0.8rem; } }

@media (max-width: 768px) {
  .paddingit {
    padding: 0; }
  header #header_right #header_right_up,
  header #header_right #header_right_down {
    width: auto; }
  #newordercontent,
  #servicenavi_top {
    display: none; }
  #bottomservicenavi {
    width: 100%; }
    #bottomservicenavi a {
      width: 50%;
      display: inline-block;
      text-align: center;
      padding: 3vw 0; }
    #bottomservicenavi #topservice_69 {
      display: none; }
  #newordermobilecontent {
    display: block; }
  .item-facebook {
    width: 100%; }
    .item-facebook + .item-instagram img {
      float: right; }
  .item-instagram {
    width: 50%; }
  h2 {
    line-height: 1.2em; }
  body #mobilemenu .main-menu li.sub {
    display: block;
    float: none;
    font-size: 1.4rem;
    padding-top: 1em;
    padding-bottom: 1em; }
    body #mobilemenu .main-menu li.sub:last-child {
      padding: 0 1em; }
    body #mobilemenu .main-menu li.sub a {
      padding: .5em 1rem;
      width: auto;
      display: inline; }
    body #mobilemenu .main-menu li.sub .paddingit.backgroundit {
      border-top: 0;
      position: static;
      padding: 0;
      margin-top: 0; }
      body #mobilemenu .main-menu li.sub .paddingit.backgroundit ul {
        padding: 2rem 0 3rem 0; }
        body #mobilemenu .main-menu li.sub .paddingit.backgroundit ul li.subli {
          width: 100%;
          height: 5rem; }
          body #mobilemenu .main-menu li.sub .paddingit.backgroundit ul li.subli a {
            float: left;
            width: 100%; }
          body #mobilemenu .main-menu li.sub .paddingit.backgroundit ul li.subli .liner {
            display: none; }
    body #mobilemenu .main-menu li.sub.open .paddingit.backgroundit {
      max-width: 0; }
    body #mobilemenu .main-menu li.sub.open.hover .paddingit.backgroundit {
      max-width: none; }
  .halber {
    width: 100%;
    margin-left: 0 !important; }
  .zweihalber .halber + .halber {
    margin-top: 3em; } }

@media (max-width: 500px) {
  #logo {
    width: 60%; }
  #header_right {
    width: 30%; }
  header #header_right #header_right_down #langmenu {
    /*
		.langwrap{
			display: none;
		}
		*/
    display: flex; }
    header #header_right #header_right_down #langmenu .langwrap {
      margin-top: -0.5em; }
      header #header_right #header_right_down #langmenu .langwrap:after {
        display: none; }
  .homelink .rechts .homelink_bild:first-child {
    display: block; }
  .homelink .rechts .homelink_bild:last-child {
    display: none; }
  .homelink a .homelink_bild {
    width: 100%; }
  .homelink a .home_text {
    width: 100%;
    padding-bottom: 5rem; }
  .quickinfo_wrap .pagelinkright {
    width: 100%; }
  .quickinfo_wrap .downloadright {
    width: 100%;
    margin-left: 0; }
  .dopadding {
    padding: 20px 0; }
  footer .halber {
    width: 100%;
    margin-left: 0 !important;
    text-align: center; }
    footer .halber .noh3 h3 {
      display: none; }
  .google_addr input {
    width: 80%; }
    .google_addr input[type="submit"] {
      width: 20%; }
  .headerbild .headertext {
    right: 1rem; }
    .headerbild .headertext h2 {
      font-size: 2rem; }
      .headerbild .headertext h2 i {
        font-size: 2rem; }
      .headerbild .headertext h2 b {
        font-size: 2rem; }
  h1 {
    font-size: 2rem; }
    h1 b {
      font-size: 2rem; }
  body #mobilemenu .main-menu li.sub a {
    padding: 0; }
  .wrap_kalenderdetail .datum_wrap {
    width: 100%;
    padding-right: 0; }
  .wrap_kalenderdetail .event_text {
    width: 100%;
    margin-top: 3rem;
    text-align: center; }
  footer .backgroundit #bottomnavi li {
    float: none;
    display: inline; } }

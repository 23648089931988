/*
================================================================
                    CSS Document
          (c) SPOT Werbung Duri Zisler
                         2016
================================================================
*/

/*
================
DEFINITIONEN:
================
*/
*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family:$mainfont;
  font-size:15px;
  line-height: 1.6em;
}
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,input,textarea,p,blockquote,th,td,label,fieldset,legend,header,nav,section,article,aside,footer,hgroup,time,figure{
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
table{
  border-collapse: collapse;
  border-spacing: 0;
  table-layout:fixed;
  word-wrap: break-word;
}
a{
  outline:none;
  text-decoration:none;
}
a:hover{
  text-decoration:none;
}
fieldset,img{
  border: 0;
  /*width:100%;*/
  float:left;
}
address,caption,cite,code,dfn,em,th,var{
  font-style:normal;
  font-weight:normal;
}
ol,ul{
  list-style: none;
  float:left;
  width:100%;
}
h1,h2,h3,h4,h5,h6{
  font-size: 100%;
  font-weight: normal;
  float:left;
  width:100%;
}
sup,
sub{
  font-size: 0.7em;
}
p,div,table,fieldset,form,header,nav,section,article,aside,footer,hgroup,time,input,figure{
  float:left;
  width:100%;
 max-width: 100%;
}
input[type="checkbox"],
input[type="radio"]{
  width:auto;
}
.container{
  float:none;
}
.row,
.mfp-container div,
#ui-datepicker-div div,
#TSFE_ADMIN_PANEL_FORM{
  width:auto;
  float:none;
}
.mfp-container .mfp-bottom-bar{
  width:100%;
}
.gmap-canvas-content.canvas-content div,
.pp_pic_holder p,
.pp_pic_holder div,
#map_canvas div,
.at3winwrapper div{
  float:none;
  width:auto;
}
.gmap-canvas-content.canvas-content .gm-style-iw,
#map_canvas .gm-style-iw{
  height: auto !important;
}
.gmap-canvas-content.canvas-content .gm-style-iw div,
#map_canvas .gm-style-iw div{
  overflow: hidden !important;
}
.gmap-canvas-content.canvas-content img,
#map_canvas img{
  max-width:none;
}
.gmap-canvas-content.canvas-content .google_addr div,
#map_canvas .google_addr div{
  float:left;
}
.google_addr{
  background: $white-color;
  padding: 2rem;
  input{
    width: 50%;
  }
}
.mfp-wrap{
  z-index: 90000 !important;
}
.mfp-iframe-holder .mfp-content{
  max-width: 98% !important;
}
#map_canvas{
  p,div,table,fieldset,form,header,nav,section,article,aside,footer,hgroup,time,input,figure{
    max-width: none;
  }
}

body{
  font-family:$mainfont;
  font-size:15px;
  line-height: 1.3333em;
  color: #646469;
  background: $bgcolor;
  /*-webkit-text-stroke: 0.1px;*/
}
/*
div{
     max-width: 1980px;
}
*/
.centerit{
  width:1400px;
  float:none;
  margin:0 auto;
}
.noblock{
  float:none !important;
  width:auto !important;
  display:inline !important;
}
.bold{
  font-weight: bold;
}
.block{
  float: left;
  width: 100%;
  display: block;
}
.center{
  text-align: center;
}
.right{
  float:right;
  text-align: right;
}
.textright{
  text-align:right;
  *{
    display: inline-block;
    float:none !important;
  }
}
.autowidth{
  width:auto;
}
.relative{
  position: relative;
}
.smallfont{
  font-size: 0.9em;
}
.spalten,
.cols2{
  -moz-column-count:    2;
  -webkit-column-count: 2;
  column-count:         2;

  -moz-column-gap:      1.5em;
  -webkit-column-gap:   1.5em;
  column-gap:           1.5em;
}
.cols3{
  -moz-column-count:    3;
  -webkit-column-count: 3;
  column-count:         3;

  -moz-column-gap:      1.5em;
  -webkit-column-gap:   1.5em;
  column-gap:           1.5em;
}
.drittel{
  width:32%;
  min-height: 1px;
}
.drittel + .drittel{
  margin-left: 2%
}
.drittel:nth-of-type(3n+4){
  margin-left:0;
}
.zweidrittel{
  width:66%;
  min-height: 1px;
}
.zweidrittel + .drittel,
.drittel + .zweidrittel{
  margin-left: 2%;
}
.halber,
.m_halber{
  width:49%;
  min-height: 1px;
  margin-left: 0;
}
.halber:nth-child(even){
  margin-left: 2%;
}
/*
.halber + .halber{
     margin-left:2%;
}
.halber:nth-of-type(n+3){
     margin-left:0;
}
div[class~=halber]:nth-of-type(n+3){
     margin-left:0;
}
*/
.zweihalber + .zweihalber{
  margin-top: 3em;
}
.halbernogap{
  width:50%;
}
img,
object{
  max-width: 100%;
  height: auto;
}
.dogrow{
  width: 100%;
  height: auto;
}
button{
  float:left;
}
.viertel{
  width:22%;
  min-height: 1px;
}
.viertel + .viertel{
  margin-left:4%;
}

.issuuembed {
  width:100% !important;
  height: auto !important;
  position: relative;
  padding-bottom: 62.25%;
  overflow: hidden;
}
.issuuembed object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.float{
  float:left;
}
.bold{
  font-weight: bold;
}
.nodesktop,
.nodisplay{
  display: none;
}
.noshow{
  max-height: 0;
  overflow:hidden;
 // .standardtransition;
}
p + p{
  margin-top:0.8em;
}
.mobilonly{
  display: none;
}
.nofloat{
  float:none;
}
.middle{
  vertical-align: middle;
}
svg{
  max-height:100%;
  height: auto;
}
.paddingit{
  padding: 0 $lepadding;
}
.backgroundit{
  background: $white-color;
}
.nomobile{
	display: none;
}

#mobilemenu{
	.main-menu{
		padding-top: 2.8em;
	}
	.sub{
		&.open,
		&.hover{
			.paddingit.backgroundit{
				max-height: none;
			}
		}
		&.hover{
			&>a{
				color:$theme-base-color;
				&:after{
					visibility: visible;
				    -webkit-transform: scaleX(1);
				    -ms-transform: scaleX(1);
				    transform: scaleX(1);
				}
			}
		}
		.paddingit.backgroundit{
			border-top: 1px solid rgba($anygray,0.5);
			margin-top: 2em;
		}
	}
}
header{
	.paddingit{
		padding:0 1.3333rem 1rem;
	}
	#logo{
		a{
			svg{
				width: 250px;
				height: auto;
				max-width: 100%;
			}
		}
	}
	#header_right{
		margin-top: 3.2rem;
		#header_right_up{
			width: 75%;
		}
		#header_right_down{
			width: 25%;
			float: right;
			.hamburger{
				display: inline-block;
			}
			nav{
				display: none;
			}
			#langmenu{
				margin: 0;
				.langwrap{
					margin-top: 0.5em;
					float: left;
					margin-right: 2rem;
					width: 56px;
					position: relative;
					background: $white-color;
					z-index: 90;
					&.show{
						a{
							display: block;
						}
					}
					a{
						display: none;
						text-transform: uppercase;
						float:left;
						text-align: center;
						width: 40px;
						color:$darktitelcolor !important;
						font-weight: 700;
						opacity: 1;
						&.active{
							display: block;
						}
					}
					&:after{
						content:url('/typo3conf/ext/spotwebsite/Resources/Public/Assets/Svg/arrow-down.svg');
						//transform:rotate(90deg);
						margin-top: -1em;
						font-size: 1.2em;
						display: inline-block;
					}
				}
			}
		}
	}	
}
.homelink{
	a{
		.home_text{
			padding-top: 1.5rem;
		}
	}
}
.dopadding {
	padding:20px 3rem;
}
.headerbild{
	.headertext{
		bottom:auto;
		top: 3rem;
	}
}
.content_wrap{
	width: 100%;
	margin-bottom: 2rem;
}
.quickinfo_wrap{
	width: 100%;
	& *:first-child{
		.righttitel{
			margin-top: 0;
		}
	}
	.pagelinkright{
		width: 49%;
	}
	.downloadright{
		width: 49%;
		margin-left: 2%;
	}
}
#largecontenthere{
	display: block;
}
.largecontent{
	display: none;
}
.item-facebook{
	.item-wrap{
		padding: 10px;
		svg{
			width: 7px;
		}
		.profile-name{
			font-size: 0.8rem;
			padding-bottom: 0.8rem;
		}
		.message,
		.description{
			font-size: 0.8rem;
		}
		.date{
			font-size:0.8rem;
			padding-top: 0.8rem;
		}
	}
}
